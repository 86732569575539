// FooterToaster.js
import React, { Component } from 'react';
import './HostGameView.scss';

export default class FooterToaster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: props.message
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.message !== this.props.message) {
            this.setState({ message: this.props.message });
        }
    }

    render() {
        return (
            <div className={`footer-toaster-wrapper ${this.props.animationClass}`}>
                <div className="footer-toaster-container">
                    {this.state.message}
                </div>
            </div>
        );
    }
}
