import React, { Component } from 'react';
import './GameView.scss';

export default class OnScreenKeyboard extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    handleKeyPress = (letter) => {
        this.props.onKeyPress(letter); 
    };

    handleDelete = () => {
        this.props.onDelete(); 
    };

    render() {
        const { round } = this.props;
        const alphabet = round === "missing_vowels" ? [['a', 'e', 'i', 'o', 'u']]
            : [
                ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
                ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'],
                ['z', 'x', 'c', 'v', 'b', 'n', 'm']
            ]
        return (
            <div className="keyboard-container">
                {alphabet.map((row, rowIndex) => (
                    <div key={rowIndex} className="keyboard-row">
                        {row.map((letter, letterIndex) => (
                            <div
                                key={letterIndex}
                                className={`keyboard-key ${round === "missing_vowels" && "big-key"}`}
                                onClick={() => this.handleKeyPress(letter)}
                            >
                                {letter}
                            </div>
                        ))}
                        {/* Add the delete key to the last row, which usually contains the "M" key */}
                        {rowIndex === alphabet.length - 1 && (
                            <div
                                className={`keyboard-key  delete-key-st ${round === "missing_vowels" && "big-key"}`}
                                onClick={this.handleDelete}
                            >
                                <div className="delete-key" /> {/* Use a separate class for styling the delete icon */}
                            </div>
                        )}
                    </div>
                ))}

                
            </div>

        );
    }
}
