import React, { Component, useRef, useEffect } from 'react';
import './HostGameView.scss';

export default class GameOver extends Component {
    constructor(props) {
        super(props);
        this.state = {
            room: props.room,
            players: props.players,
            gameState: props.gameState,
        };
    }



    render() {
        console.log("player from GameOver: ", this.props.players)

        const { players, teamAScore, teamBScore } = this.props;
        console.log("players: ", players)
        const teamAPlayers = this.props.players.filter(player => player.ucData.team === 'a');
        const teamBPlayers = this.props.players.filter(player => player.ucData.team === 'b');
        console.log("team scores from gameOver: ", 'team a: ', this.props.teamAScore, 'team b: ', this.props.teamBScore)

        return (
            <div className="game-over-wrapper one-team-container">
                <div className="game-over-container">
                    {teamAScore > teamBScore ? (
                        <>
                            <h1>Team A Wins!</h1>
                            <div class="game-over-players-wrapper" >
                                <h2>{this.props.teamAScore} {this.props.teamAScore === 1 ? 'point' : 'points'} </h2>
                                <h1>Team A</h1>
                                <div className="game-over-players-container">
                                    {
                                        teamAPlayers.map(player => (
                                            <div className="player-icon-container" >
                                                <div className={`player-icon`}>
                                                    {
                                                        player.ucData.playerAvatar.length > 2 ?
                                                            <>
                                                                <img src={player.ucData.playerAvatar} className="player-icon-image" alt="Av" />

                                                            </> :
                                                            <>
                                                                <div className="header-profile-image" >
                                                                    <div class="circle-loading2" />
                                                                </div>
                                                            </>

                                                    }
                                                    <div className="player-icon-name-container">
                                                        <h3 key={player.id}>{player.name}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </>
                    ) : teamAScore < teamBScore ? (
                        <>
                            <h1>Team B Wins!</h1>

                        </>
                    ) :
                        <>
                            <h1>Teams are Tied!</h1>
                            <div class="game-over-players-draw">
                                <div class="game-over-players-wrapper">
                                        <h2>{this.props.teamAScore}  {this.props.teamBScore === 1 ? 'point' : 'points'}</h2>
                                    <h1>Team A</h1>
                                    <div className="game-over-players-container">
                                        {
                                            teamAPlayers.map(player => (
                                                <div className="player-icon-container" >
                                                    <div className={`player-icon`}>
                                                        {
                                                            player.ucData.playerAvatar.length > 2 ?
                                                                <>
                                                                    <img src={player.ucData.playerAvatar} className="player-icon-image" alt="Av" />

                                                                </> :
                                                                <>
                                                                    <div className="header-profile-image" >
                                                                        <div class="circle-loading2" />
                                                                    </div>
                                                                </>

                                                        }
                                                        <div className="player-icon-name-container">
                                                            <h3 key={player.id}>{player.name}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div class="game-over-players-wrapper">
                                    <h2>{this.props.teamBScore} Points</h2>
                                    <h1>Team B</h1>
                                    <div className="game-over-players-container">
                                        {
                                            teamBPlayers.map(player => (
                                                <div className="player-icon-container" >
                                                    <div className={`player-icon`}>
                                                        {
                                                            player.ucData.playerAvatar.length > 2 ?
                                                                <>
                                                                    <img src={player.ucData.playerAvatar} className="player-icon-image" alt="Av" />

                                                                </> :
                                                                <>
                                                                    <div className="header-profile-image" >
                                                                        <div class="circle-loading2" />
                                                                    </div>
                                                                </>

                                                        }
                                                        <div className="player-icon-name-container">
                                                            <h3 key={player.id}>{player.name}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>

                <div className="start-new-game-container">
                    <button className="game-over-button" onClick={() => {this.state.room.send("restart_game")}}>NEW GAME</button>

                </div>
            </div>

        );
    }
}
