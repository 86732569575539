import React, { Component, useEffect } from 'react';
import './HostGameView.scss';

export default class CategorySelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            room: props.room,
            players: props.players,

        }
    }

    render() {
        return (
            <div className="host-category-select-container">
                <h1>Welcome to Universally Challenged.</h1>
                <h2>Before we start playing, please complete your profile</h2>
            </div>
        )
    }
}