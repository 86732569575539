import React, { Component } from 'react';
import './GameView.scss';

export default class WordBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            room: props.room,
            player: props.player,
            word: props.word,
        };
    }



    render() {
        return (
            <div className="word-box-container">
                <h1>{this.props.userInput}</h1>
            </div>
        );
    }
}