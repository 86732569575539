import React, { Component, useState, useEffect } from 'react';
import './HostGameView.scss';

export default class PictureFrame extends Component {
    constructor(props) {
        super(props);
        this.state = {
            room: props.room,
            players: props.players,
            answer: props.correctAnswer,
            PictureFrameData: props.PictureFrameData || '' 
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.PictureFrameData !== this.props.PictureFrameData) {
            this.setState({ PictureFrameData: this.props.PictureFrameData });
        }

        //if (prevProps.correctAnswer !== this.props.correctAnswer) {

        //    const correctAnswer = Array.isArray(this.props.correctAnswer)
        //        ? this.props.correctAnswer.join('')
        //        : this.props.correctAnswer;

        //    this.setState({ answer: correctAnswer });
        //}
    }

    render() {
        return (
            <div className="picture-frame-container">
                {this.state.PictureFrameData && (
                    <img src={this.state.PictureFrameData} alt="PictureFrame" className="picture-frame-image" />
                )}
                <ConnectFourLetterBox answer={ this.props.correctAnswer} />
            </div>
        );
    }
}

const ConnectFourLetterBox = ({ answer }) => {
    const [splitAnswer, setSplitAnswer] = useState([]);

    useEffect(() => {

        const formattedAnswer = Array.isArray(answer) ? answer.join('') : answer;
        const slicedWord = typeof formattedAnswer === 'string' ? formattedAnswer.split('') : [];

        setSplitAnswer(slicedWord);
    }, [answer]);


    return (
        <div className="connect-four-letter-box-container">
            {splitAnswer.map((letter, index) => (
                <div key={index} className={`connect-four-letter ${letter === ' ' ? 'connect-four-space' : ''}`}>
                </div>
            ))}
        </div>
    );
};