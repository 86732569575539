import React, { Component, useState, useEffect } from 'react';
import './HostGameView.scss';

export default class ConnectFour extends Component {
    constructor(props) {
        super(props);
        this.state = {
            room: props.room,
            players: props.players,
            ConnectFourData: props.ConnectFourData,
            answer: props.correctAnswer,  
        };
    }

    componentDidMount() {
      

    }

    componentWillUnmount() {
        clearInterval(this.hintInterval);
        clearInterval(this.capsuleInterval);
    }

    componentDidUpdate(prevProps) {
        //console.log("Connect Four Data: ", this.props.ConnectFourData);
        if (prevProps.ConnectFourData !== this.props.ConnectFourData) {
            this.setState({ ConnectFourData: this.props.ConnectFourData.filter(x => x != null) });
        }

       
    }

    render() {
        const hintOffset = (this.props.hintsRevealed * 10.8);
        console.log("Hints Revealed : ", this.props.hintsRevealed);
        return (
            <div className="host-connect-four-container">
                <div className="host-connect-four-hints-wrapper">
                    <div className={`connect-four-points-container ${this.props.isBuzzTimer && "flash"}`} style={{ transform: `translateY(${hintOffset}vh)` }}>
                        {Array.from({ length: 60 }, (_, i) => (
                            <div key={i} className="connect-four-sec" style={{ transform: `rotate(${-6 * i}deg)` }}>
                                <div
                                    className={`connect-four-capsule capsule${i}`}
                                    style={{
                                        backgroundColor: i <= this.props.timer ? '#18538f' : '#98cde6',
                                        opacity: i <= this.props.timer ? 1 : 0.5,
                                    }}
                                />
                            </div>
                        ))}
                        <h1>{this.props.timer}</h1>
                        
                    </div>
                    {this.props.ConnectFourData?.filter(x => x).slice(0, this.props.hintsRevealed + 1).map((hint, index) => (
                        <div className="host-connect-four-hint-wrapper slide-up" key={index}>
                            <div className="host-connect-four-hint-container">
                                <h2 className="bold-header">{hint}</h2>
                            </div>
                        </div>
                    ))}
                </div>
                <ConnectFourLetterBox answer={this.props.correctAnswer} showCorrectAnswer={this.props.showCorrectAnswer } />
            </div>
        );
    }
}

const ConnectFourLetterBox = ({ answer, showCorrectAnswer }) => {
    const [splitAnswer, setSplitAnswer] = useState([]);

    useEffect(() => {
        const formattedAnswer = Array.isArray(answer) ? answer.join('') : answer;
        const slicedWord = typeof formattedAnswer === 'string' ? formattedAnswer.split('') : [];
        setSplitAnswer(slicedWord);
        console.log(slicedWord);
    }, [answer]);

    return (
        <div className={`connect-four-letter-box-container ${showCorrectAnswer ? 'show-correct-answer-bounce' : 'CFLB-start'}`}>
            {splitAnswer.map((letter, index) => (
                <div key={index} className={`connect-four-letter ${letter === ' ' ? 'connect-four-space' : ''}`}>
                    {
                        showCorrectAnswer && (
                            <h1>{letter}</h1>
                        )
                    }
                </div>
            ))}
        </div>
    );
};
