import React, { Component } from 'react';
import './GameView.scss';

export default class ConfirmButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            room: props.room,
            player: props.player,

        };
    }

    confirmClick = () => {
        this.props.onConfirm()
    }


    render() {
        return (
            <div className="confirm-button-container">
                <div onClick={this.confirmClick} className="confirm-button">
                   SUBMIT
                </div>
            </div>
        );
    }
}
