import React, { Component, useEffect } from 'react';
import './CategorySelect.scss';

export default class CategorySelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            room: props.room,
            player: props.player,
        }
    }


    render() {
        return (
            <div className="category-select-container">
                <h3>Waiting for new round</h3>
            </div>
        );
    }

}