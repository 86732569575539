import React, { Component, useEffect } from 'react';
import './HostGameView.scss';

export default class TeamWidgetContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            room: props.room,
            players: props.players,

        }//
    }

    render() {
        const teamAPlayers = this.props.players.filter(player => player.ucData.team === 'a');
        const teamBPlayers = this.props.players.filter(player => player.ucData.team === 'b');

        const teamAScore = this.props.teamAScore;
        const teamBScore = this.props.teamBScore;
        const unplacedPlayers = this.props.players.filter(player => !player.ucData.team);

        let teamAHighlight = "#fff", teamBHighlight = "#fff";
        if (this.props.buzzedInPlayerId.length > 0) {

            const buzzedPlayer = this.props.players.find(x => x.id === this.props.buzzedInPlayerId);
            if (buzzedPlayer) {
                if (buzzedPlayer.ucData.team === "a") {
                    teamAHighlight = "blue";
                } else if (buzzedPlayer.ucData.team === "b") {
                    teamBHighlight = "blue";
                }
            }
        }

        if (this.props.teamALocked) {
            if (this.props.teamACorrect) {
                teamAHighlight = "green";
            } else {
                teamAHighlight = "red";
            }
        }
        if (this.props.teamBLocked) {
            if (this.props.teamBCorrect) {
                teamBHighlight = "green";
            } else {
                teamBHighlight = "red";
            }
        }

        console.log("Team A Highlight : ", teamAHighlight);
        console.log("Team B Highlight : ", teamBHighlight);

        return (
            <div className="host-selecting-team-container">
                <div className="host-selecting-team-teams-container">
                    <div className="host-selecting-team-team-container">
                        <div className="host-team-info-container" >
                            <h2 className="team-header-style">TEAM A</h2>
                            <div className="host-team-points-container" style={{ border: `0.5vh solid ${teamAHighlight}` }}>
                                <h1>{teamAScore}</h1>
                            </div>
                        </div>
                        <div className={`host-selecting-team-player-wrapper`} style={{ border: `0.5vh solid ${teamAHighlight}`}}>
                            <div className={`host-selecting-team-player-container player-container-grid${teamAPlayers.length}`} style={{ border: `0.5vh solid ${teamAHighlight}` }}>
                                {teamAPlayers.map(player => (
                                    <div className="player-icon-container" >
                                        <div className={`player-icon ${(player.ucData.hasSubmittedAnswer || this.props.buzzedInPlayerId === player.id) && this.props.round !== 'where_in_the_world' && "highlight"}`} style={
                                            this.props.round === "where_in_the_world"
                                                ? { backgroundColor: player.ucData.whereInTheWorldColor }
                                                : {}
                                        }>
                                            {
                                                player.ucData.playerAvatar.length > 2 ?
                                                    <>
                                                        <img src={player.ucData.playerAvatar} className="player-icon-image" alt="Av" />

                                                    </> :
                                                    <>
                                                        <div className="header-profile-image" >
                                                            <div class="circle-loading2" />
                                                        </div>
                                                    </>
                                            }
                                            <div className={`player-icon-name-container ${player.ucData.hasSubmittedAnswer && "highlight"}`}>
                                                <h3 key={player.id}>{player.name}</h3>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                        </div>
                    </div>

                    <div className="unplaced-teams">
                        <div className="unplaced-players-grid">
                            {unplacedPlayers.map(player => (
                                <div key={player.id} className="unplaced-player animated-player">
                                    <div className="player-icon2">
                                        {
                                            player.ucData.playerAvatar.length > 2 ?
                                                <>
                                                    <img src={player.ucData.playerAvatar} className="player-icon-image2" alt="Av" />

                                                </> :
                                                <>
                                                    <div className="header-profile-image" >
                                                        <div class="circle-loading2" />
                                                    </div>
                                                </>

                                        }
                                        <div className="player-icon-name-container2">
                                            <h3 key={player.id}>{player.name}</h3>
                                        </div>
                                    </div>

                                </div>
                            ))}
                        </div>
                    </div>


                    <div className="host-selecting-team-team-container">
                        <div className="host-team-info-container">
                            <h2 className="team-header-style">TEAM B</h2>
                            <div className="host-team-points-container" style={{ border: `0.5vh solid ${teamBHighlight}` }}>
                                <h1>{teamBScore}</h1>
                            </div>
                        </div>
                        <div className={`host-selecting-team-player-wrapper`} style={{ border: `0.5vh solid ${teamBHighlight}` }}>
                            <div className={`host-selecting-team-player-container player-container-grid${teamBPlayers.length}`} style={{ border: `0.5vh solid ${teamBHighlight}` }}>


                                {teamBPlayers.map(player => (
                                    <div className="player-icon-container">
                                        <div className={`player-icon ${(player.ucData.hasSubmittedAnswer || this.props.buzzedInPlayerId === player.id) && this.props.round !== 'where_in_the_world' && "highlight"}`} style={
                                            this.props.round === "where_in_the_world"
                                                ? { backgroundColor: player.ucData.whereInTheWorldColor }
                                                : {}
                                        }>
                                            {
                                                player.ucData.playerAvatar.length > 2 ?
                                                    <>
                                                        <img src={player.ucData.playerAvatar} className="player-icon-image" alt="Av" />

                                                    </> :
                                                    <>
                                                        <div className="header-profile-image" >
                                                            <div class="circle-loading2" />
                                                        </div>
                                                    </>

                                            }
                                            <div className={`player-icon-name-container ${player.ucData.hasSubmittedAnswer && "highlight"}`}>
                                                <h3 key={player.id}>{player.name}</h3>
                                            </div>

                                        </div>


                                    </div>

                                ))}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

