import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    PaymentElement,
    LinkAuthenticationElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import Spinner from 'react-bootstrap/Spinner';

import styles from "../CheckoutStyles.module.scss";
import { Link, } from "react-router-dom";
import StripeService from "services/stripe.service";
import { authoriseUser } from "actions/auth";
import StripeLogo from "images/stripe-blurple.svg";
import auth from "../../reducers/auth";

export default function CheckoutForm(props) {
    const stripe = useStripe();
    const elements = useElements();

    //const navigate = useNavigate();
    const { isLoggedIn, user, isAuthorised } = useSelector(state => state.auth);
    // setup redux
const dispatch = useDispatch();

    const [email, setEmail] = useState('');
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isStripeLoading, setIsStripeLoading] = useState(true);

    useEffect(() => {
        if (elements) {
            const element = elements.getElement('payment')
            element.on('ready', () => {
                setIsStripeLoading(false)
            })
            element.on('loaderror', () => {
                console.log("stripe load error")
            })
        }
    }, [elements])


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: "https://localhost:44437/checkout-return",
                receipt_email: email,
            },
            redirect: "if_required"
        }).then((res) => {
            console.log("confirm payment res : ", res);
            if (res.paymentIntent) {
                //navigate("/checkout-return?payment_intent=" + res.paymentIntent.id);
                StripeService.verifyAndRedeemCode(res.paymentIntent.id).then((res) => {
                    console.log("verifyAndRedeemCode res : ", res);
                    dispatch(authoriseUser());
                }).catch((err) => {
                    console.log("verifyAndRedeemCode err : ", err);

                });
            } else {
                if (res.error) {
                    setMessage(res.error.message);
                }
                setIsLoading(false);
            }
        }).catch((error) => {
            if (error.type === "card_error" || error.type === "validation_error") {
                setMessage(error.message);
            } else {
                setMessage("An unexpected error occurred.");
            }

            setIsLoading(false);
        });
    };

    const paymentElementOptions = {
        layout: "tabs"
    }

    //const linkAuthenticationElementOptions = {
    //    defaultValues: {
    //        email: props.email
    //    }
    //};

    //const updateEmail = (event) => {
    //    setEmail(event.value.email);
    //}

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            {isStripeLoading && <Spinner animation="border" />}
            {/*<LinkAuthenticationElement id="link-authentication-element" onChange={updateEmail} options={linkAuthenticationElementOptions} />*/}
            <PaymentElement className={styles.paymentElement} id="payment-element" options={paymentElementOptions} />
            {message && <div className={styles.paymentMessage} id="payment-message">{message}</div>}
            {
                !isStripeLoading &&
                <button className={`${styles.button} mt-3`} disabled={isLoading || !stripe || !elements} id="submit">
                    {isLoading ? <div className={styles.spinner} id="spinner"></div> : "Pay now"}
                </button>
            }
            <br/>
            <img className="mt-2" src={StripeLogo} style={{ width: "175px" }} />
            {/* Show any error or success messages */}
            <p className="mt-2">
                By continuing you are agreeing to the <Link to="/terms">Game.City Terms</Link>
            </p>
        </form>
    );
}