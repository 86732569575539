import { useHistory } from 'react-router-dom';

export const withRouter = (Component) => {
    const Wrapper = (props) => {
        const history = useHistory();

        const navigate = (route) => history.push(route);
        return (
            <Component
                navigate={navigate}
                {...props}
            />
        );
    };

    return Wrapper;
};