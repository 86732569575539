import React, { Component } from 'react';
import './HostGameView.scss';

export default class SpellingBee extends Component {
    constructor(props) {
        super(props);
        this.state = {
            room: props.room,
            players: props.players,
            SpellingBeeData: props.SpellingBeeData || '' 
        };
        this.audioRef = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.SpellingBeeData !== this.props.SpellingBeeData) {
            const mp3Url = this.props.SpellingBeeData.filter(x => x)[0];
            this.setState({ SpellingBeeData: mp3Url }, () => {
                if (this.state.SpellingBeeData && this.audioRef.current) {
                    console.log("Spelling Bee Data: ", this.state.SpellingBeeData);
                    this.audioRef.current.play();
                }
            });
        }
    }

    render() {
        return (
            <div className="spelling-bee-container">
                <h2>Spelling Bee</h2>
                {this.state.SpellingBeeData && (
                    <audio
                        ref={this.audioRef}
                        src={this.state.SpellingBeeData}
                        controls
                       
                    />
                )}
            </div>
        );
    }
}
