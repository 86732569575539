//import background from "../images/background.png";

const images = [
    //background,
];

const fonts = [
    //{ font: "Gagalin", path: `https://${window.location.host}/Assets/gagalin.ttf`},
];


const WSErrorCodes = {
    1001: "Browser Tab Closing",
    1002: "Malformed framce received",
    1003: "Unsupported frame received",
    1006: "GS ungraceful shutdown / connection refused",
    1011: "Internal Error",
    1012: "Server Restarting",
    1013: "Try again later, server capacity full",
    1014: "Bad Gateway",
    4000: "Gameserver graceful shutdown",
    4050: "Player removed"
};

export default {
    images,
    fonts,
    WSErrorCodes
};