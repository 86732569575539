import React, { Component } from 'react';
import './SelectingTeam.scss';

export default class SelectingTeam extends Component {
    constructor(props) {
        super(props);
        this.state = {
            room: props.room,
            player: props.player,
            players: props.players,
            team: null,
        }
    }

    selectTeam(team) {
        this.props.room.send("select_team", {
            data: {
                team: team
            }
        });
        this.setState({ team: team })
    }

    render() {
        const teamAPlayers = this.state.players.filter(player => player.ucData.team === 'a');
        const teamBPlayers = this.state.players.filter(player => player.ucData.team === 'b');
        return (
            <div className="selecting-team-container">
                <div className="game-view-header-profile-icon2">
                    {
                        this.state.player.ucData.playerAvatar.length > 2 ?
                            <>
                                <img src={this.state.player.ucData.playerAvatar} alt="Avatar" className="header-profile-image" />
                            </> :
                            <>
                                <div className="header-profile-image" >
                                    <div class="circle-loading" />
                                </div>
                            </>

                    }
                    <div className="game-view-header-profile-name">
                        <h3>
                            {this.state.player.name}
                        </h3>
                    </div>
                </div>
                <h1>Please select your team</h1>
                <div className="selecting-team-buttons">
                    <div className="team-container">
                        <button onClick={() => this.selectTeam("a")} className={`${this.state.team === "a" ? "team-selected" : ""}`}>
                            Team A
                        </button>

                    </div>
                    <div className="team-container">
                        <button onClick={() => this.selectTeam("b")} className={`${this.state.team === "b" ? "team-selected" : ""}`}>
                            Team B
                        </button>

                    </div>
                </div>
            </div>
        );
    }
}







//export default class WordLetters extends Component {
//    constructor(props) {
//        super(props);
//        this.state = {
//            room: props.room,
//            round: props.round,
//            player: props.player,
//            players: props.players,
//            answer: props.answer || '',
//        };
//    }

//    componentDidUpdate(prevProps) {
//        if (this.props.answer !== prevProps.answer) {
//            console.log("Updating answer in WordLetters");

//            const answer = Array.isArray(this.props.answer)
//                ? this.props.answer.join('')
//                : this.props.answer;
//            this.setState({ answer });
//            console.log("Answer updated: ", this.state.answer);
//        }

//        if (this.props.round !== prevProps.round) {
//            console.log("updating round in WordLetters");

//            this.setState({ round: this.props.round });
//        }
//    }

//    render() {
//        const { userInput, round } = this.props;
//        const slicedWord = typeof this.state.answer === 'string' ? this.state.answer.split('') : [];

//        let userInputIndex = 0;

//        return (
//            <div className="word-letters-wrapper">
//                <div className="word-letters-container">
//                    {slicedWord.map((letter, index) => {
//                        if (letter === " ") {
//                            return <div key={index} className="space-placeholder"></div>;
//                        }

//                        const isVowel = /[aeiouAEIOU]/.test(letter);

//                        return (
//                            <div key={index} className="user-input-character">
//                                {round === "missing_vowels" ? (
//                                    <h1>
//                                        {isVowel
//                                            ? userInput[userInputIndex++] || ""
//                                            : letter}
//                                    </h1>
//                                ) : (
//                                    <h1>
//                                        {this.props.userInput && this.props.userInput[index]
//                                            ? this.props.userInput[index]
//                                            : ""}
//                                    </h1>
//                                )}
//                            </div>
//                        );
//                    })}
//                </div>
//            </div>

//        );
//    }

//}
