import React, { Component } from 'react';
import './CategorySelect.scss';
import Stencil from "../../images/Stencil.png";

export default class UploadAvatar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            room: props.room,
            player: props.player,
            playerAge: 0,
            isCameraOn: false,
            capturedImage: null,
            base64Data: null,
            stream: null,
            playerAvatar: null,
            loadingImage: false,
            selectedStyle: null,
            selectedPrompt: null,
            isCreatingOwnAvatar: false,
            selectedPreMadeAvatar: null,

            userPrompt: null,
            userStyle: null,

            playerHasConfirmed: false,
        };

        this.videoRef = React.createRef();
        this.canvasRef = React.createRef();

        this.preMadeAvatars = [
            { id: 'create-own', label: 'Create My Own', image: null },
            { id: 'avatar1', label: 'Avatar 1', image: 'https://big-potato-tv.s3.eu-west-2.amazonaws.com/UniversallyChallenged/avatars/gen/GEN_nutcracker2.JPG' },
            { id: 'avatar2', label: 'Avatar 2', image: 'https://big-potato-tv.s3.eu-west-2.amazonaws.com/UniversallyChallenged/avatars/gen/GEN_santa.jpg' },
            { id: 'avatar3', label: 'Avatar 3', image: 'https://big-potato-tv.s3.eu-west-2.amazonaws.com/UniversallyChallenged/avatars/gen/GEN_scrooge.jpg' },
            { id: 'avatar4', label: 'Avatar 4', image: 'https://big-potato-tv.s3.eu-west-2.amazonaws.com/UniversallyChallenged/avatars/gen/GEN_elf.jpg' },
            { id: 'avatar5', label: 'Avatar 5', image: 'https://big-potato-tv.s3.eu-west-2.amazonaws.com/UniversallyChallenged/avatars/gen/GEN_mrsclaus.jpg' },
        ];
    }

    componentDidUpdate(prevProps) {
        if (this.props.player !== prevProps.player) {
            this.setState({ playerAvatar: this.props.player.ucData.playerAvatar, loadingImage: false });
            console.log(this.props.player);
        }
    }

    startCamera = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            this.setState({ isCameraOn: true, stream }, () => {
                if (this.videoRef.current) {
                    this.videoRef.current.srcObject = stream;
                }
            });
        } catch (error) {
            console.error('Error accessing camera:', error);
            alert(`Unable to access the camera: ${error.message}`);
        }
    };

    stopCamera = () => {
        const { stream } = this.state;
        if (stream) {
            stream.getTracks().forEach((track) => track.stop());
        }
        this.setState({ isCameraOn: false, stream: null });
    };

    takePicture = () => {
        const video = this.videoRef.current;
        const canvas = this.canvasRef.current;
        const context = canvas.getContext('2d');

        const ratio = video.videoWidth / video.videoHeight;
        console.log(ratio);
        canvas.width = 200 * ratio;// video.videoWidth;
        canvas.height = 200; // video.videoHeight;

        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        const capturedImage = canvas.toDataURL('image/png', 0.5);
        const base64Data = capturedImage.split(',')[1];

        this.stopCamera();
        this.setState({ capturedImage, base64Data });
    };

    retakePicture = () => {
        this.setState({ capturedImage: null, base64Data: null }, () => {
            this.startCamera();
        });
    };

    uploadImage = () => {
        const { base64Data, selectedStyle, selectedPrompt } = this.state;
        this.state.room.send('upload_avatar', {
            image: base64Data,
            style: selectedStyle,
            prompt: selectedPrompt,
            
        });
        this.setState({
            loadingImage: true,
        });
    };

    handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            //if (file.size > 1 * 1024 * 1024) {
                const compressedDataUrl = await this.compressImage(file);
                const base64Data = compressedDataUrl.split(',')[1];
                this.setState({
                    capturedImage: compressedDataUrl,
                    base64Data,
                });
            //} else {
            //    const reader = new FileReader();
            //    reader.onloadend = () => {
            //        const base64Data = reader.result.split(',')[1];
            //        this.setState({
            //            capturedImage: reader.result,
            //            base64Data,
            //        });
            //    };
            //    reader.readAsDataURL(file);
            //}
        }
    };

    compressImage = (file) => {
        return new Promise((resolve) => {
            const img = new Image();
            const reader = new FileReader();

            reader.onload = (e) => {
                img.src = e.target.result;
            };

            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                const MAX_WIDTH = 200; 
                const MAX_HEIGHT = 200; 
                let width = img.width;
                let height = img.height;

                if (width > height) {
                    if (width > MAX_WIDTH) {
                        height = (height * MAX_WIDTH) / width;
                        width = MAX_WIDTH;
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width = (width * MAX_HEIGHT) / height;
                        height = MAX_HEIGHT;
                    }
                }

                canvas.width = width;
                canvas.height = height;
                ctx.drawImage(img, 0, 0, 200, 200);

                // Compress the image to reduce file size
                const compressedDataUrl = canvas.toDataURL('image/jpeg', 0.7); // 0.7 = quality (70%)
                resolve(compressedDataUrl);
            };

            reader.readAsDataURL(file);
        });
    };


    handleStyleSelect = (style) => {
        this.setState({ selectedStyle: style });
    };

    handlePromptSelect = (prompt) => {
        this.setState({ selectedPrompt: prompt });
    };

    handleAvatarSelect = (avatar) => {
        if (avatar.id === 'create-own') {
            this.setState({ isCreatingOwnAvatar: true });
        } else {
            this.setState({ selectedPreMadeAvatar: avatar });
        }
    };

    uploadPreMadeAvatar = () => {
        this.state.room.send('select_pre_made_avatar', {
            avatarUrl: this.state.selectedPreMadeAvatar.image,
            
        });
        this.setState({
            loadingImage: true,
            playerHasConfirmed: true,
        });
    };

    goBack = () => {
        this.setState({
            selectedPreMadeAvatar: null,
            isCreatingOwnAvatar: false,
            capturedImage: null,
            base64Data: null,
        });
    };

    componentWillUnmount() {
        this.stopCamera();
    }

    componentDidMount() {
        const styles = ['3D', 'Emoji', 'Video Game', 'Pixels', 'Clay', 'Toy'];
        const prompts = ['bald', 'glasses', 'beard', '90s', 'zombie', 'christmas'];

        if (!this.state.userPrompt) {
            this.setState({ selectedPrompt: prompts[Math.floor(Math.random() * prompts.length)] });
        }
        if (!this.state.userStyle) {
            this.setState({ selectedStyle: styles[Math.floor(Math.random() * styles.length)] });
        }
        this.stopCamera();
    }

    renderPreMadeAvatars() {
        return this.preMadeAvatars.map((avatar) => (
            <div key={avatar.id} className="avatar-cell" onClick={() => this.handleAvatarSelect(avatar)}>
                {avatar.image ? (
                    <img src={avatar.image} alt={avatar.label} className="pre-made-avatar" />
                ) : (
                    <div className="create-own-avatar">
                        <h3>{avatar.label}</h3>
                    </div>
                )}
            </div>
        ));
    }

    render() {
       
        const {
            isCreatingOwnAvatar,
            selectedPreMadeAvatar,
            capturedImage,
            playerAvatar,
            loadingImage,
            selectedStyle,
            selectedPrompt,
            isCameraOn,
            playerHasConfirmed
        } = this.state;

        console.log(playerHasConfirmed)

        if (!isCreatingOwnAvatar && !selectedPreMadeAvatar) {
            return (
                <div className="upload-avatar-container">
                    <h2>Select Your Avatar</h2>
                    <h4>If you wish to participate in the Face Swap round, please upload your own avatar</h4>
                    <div className="avatar-grid">
                        {this.renderPreMadeAvatars()}
                    </div>
                </div>
            );
        }
        else if (playerHasConfirmed) {
            return (
                <div class="awaiting-player-avatars">
                    <h1>
                        WAITING FOR OTHER PLAYERS TO CONFIRM AVATARS

                    </h1>
                </div>
            )
        } else if (isCreatingOwnAvatar) {
            return (
                <div className="upload-avatar-container">
                    <h2>Upload Your Avatar</h2>

                    {!capturedImage ? (
                        <div>
                            <div className="alternative-upload">

                                {!isCameraOn && (
                                    <button onClick={this.startCamera}>Open Camera</button>
                                )}
                            </div>
                            {isCameraOn && (
                                <div className="camera-container">
                                    <img src={Stencil} className="camera-stencil" alt="" />
                                    <video
                                        ref={this.videoRef}
                                        autoPlay
                                        muted
                                        playsInline
                                        className="camera-feed"
                                        style={{ width: '100%', maxWidth: '500px', transform: 'scale(-1, 1)' }}
                                    />
                                    <button onClick={this.takePicture}>Take Picture</button>
                                    <button onClick={this.stopCamera}>Close Camera</button>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="captured-image-container">
                            <div className="avatar-preview-container">
                                <img
                                    src={playerAvatar ? playerAvatar : capturedImage}
                                    alt="Captured avatar"
                                    className="captured-image"
                                    style={{ width: '100%' }}
                                />
                                {loadingImage && (
                                    <div className="spinner-absolute">
                                        <div className="spinner">
                                            <div className="bubble-1"></div>
                                            <div className="bubble-2"></div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {!playerAvatar && !loadingImage && (
                                <div className="captured-image-buttons-container">
                                    {/*<div className="upload-image-section-buttons">*/}
                                    {/*    <h3>Please choose your style</h3>*/}
                                    {/*    <div className="upload-image-grid">*/}
                                    {/*        {styles.map((style) => (*/}
                                    {/*            <button*/}
                                    {/*                key={style}*/}
                                    {/*                className={`upload-image-grid-button ${selectedStyle === style ? 'selected' : ''*/}
                                    {/*                    }`}*/}
                                    {/*                onClick={() => this.handleStyleSelect(style)}*/}
                                    {/*            >*/}
                                    {/*                {style}*/}
                                    {/*            </button>*/}
                                    {/*        ))}*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div className="upload-image-section-buttons">*/}
                                    {/*    <h3>Please choose your prompt</h3>*/}
                                    {/*    <div className="upload-image-grid">*/}
                                    {/*        {prompts.map((prompt) => (*/}
                                    {/*            <button*/}
                                    {/*                key={prompt}*/}
                                    {/*                className={`upload-image-grid-button ${selectedPrompt === prompt ? 'selected' : ''*/}
                                    {/*                    }`}*/}
                                    {/*                onClick={() => this.handlePromptSelect(prompt)}*/}
                                    {/*            >*/}
                                    {/*                {prompt}*/}
                                    {/*            </button>*/}
                                    {/*        ))}*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <button
                                        className="upload-button"
                                        onClick={this.uploadImage}
                                        disabled={!selectedStyle || !selectedPrompt}
                                    >
                                        Upload
                                    </button>
                                </div>
                            )}
                            {!playerAvatar && !loadingImage && (
                                <div className="upload-retake-buttons-container">
                                    <button className="retake-button" onClick={this.retakePicture}>
                                        Retake
                                    </button>
                                    <button className="go-back-button" onClick={this.goBack}>
                                        Go Back
                                    </button>
                                </div>
                            )}

                        </div>
                    )}

                    <canvas ref={this.canvasRef} style={{ display: 'none' }} />
                </div>
            );
        } else if (selectedPreMadeAvatar) {
            return (
                <div className="upload-avatar-container">
                    <h2>Confirm Your Avatar</h2>
                    <div className="avatar-preview-container">
                        <img
                            src={selectedPreMadeAvatar.image}
                            alt="Selected Avatar"
                            className="pre-made-avatar-preview"
                        />
                    </div>
                    <button className="confirm-button" onClick={this.uploadPreMadeAvatar}>
                        Confirm
                    </button>
                    <button className="go-back-button" onClick={this.goBack}>
                        Go Back
                    </button>
                </div>
            );
        } 
    }
}
