import React, { Component } from 'react';
import Lottie from 'react-lottie';

import getAvatarById from "constants/avatars";
import styles from "components/PlayerStyles.module.scss";


export default class Player extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentWillReceiveProps(nextProps) {
        
    }


    render() {
        return (
            <div id={`player-${this.props.player.id}`} className={`${styles.player} ${this.props.left ? "" : styles.reverse}`}>
                <div id={`potato-${this.props.player.id}`} className={`${styles.potato}`}>
                    <Lottie
                        options={getAvatarById(this.props.player.avatar).idleAnim}
                        width="100%"
                        height="100%"
                        isClickToPauseDisabled={true}
                        style={{ filter: this.props.left ? "drop-shadow(-2vh 1vh 0px #000)" : "drop-shadow(2vh 1vh 0px #000)" }}
                    />
                </div>
                <div className={`${styles.textSection}`}>
                    <div className={`${styles.name}`}>{this.props.player.name}</div>
                    <div className={`${styles.points}`}>{this.props.player.universallyChallengedData?.score} Points</div>
                </div>
            </div>
        )
    }
}