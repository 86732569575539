import React, { Component, useEffect } from 'react';
import './CategorySelect.scss';

export default class CategorySelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            room: props.room,
            player: props.player,
            playerAge: 0,
            geniusSubjects: [],
            subGeniusSubjects: {},
        }
    }


    handleCategorySelection = (category) => {
        const { geniusSubjects } = this.state;

        if (geniusSubjects.includes(category)) {
            this.setState({
                geniusSubjects: geniusSubjects.filter(c => c !== category)
            });
        } else {
            if (geniusSubjects.length < 3) {
                this.setState({
                    geniusSubjects: [...geniusSubjects, category]
                });
            } else {
                this.setState({
                    geniusSubjects: [...geniusSubjects.slice(1), category]
                });
            }
        }
    };

    handleSubCategorySelection = (geniusSubject, subCategory) => {
        const { subGeniusSubjects } = this.state;

        this.setState({
            subGeniusSubjects: {
                ...subGeniusSubjects,
                [geniusSubject]: subCategory
            }
        });
    };


   


    confirmSelection = () => {
        if (this.state.geniusSubjects.length === 3) {
            this.props.room.send("update_player_genius_fields", {
                data: {
                    geniusSubjects: this.state.geniusSubjects,
                    playerAge: this.state.playerAge,
                    subGeniusSubjects: this.state.subGeniusSubjects,
                    readyToSelectSub: true,
                    startReady: this.props.player.ucData.readyToSelectSub ? true : false
                }
            });
        } else {
            alert('Please select exactly 3 categories!');
        }
    };

 


    render() {
        return (
            <div className="category-select-container">
                {
                    this.props.player.ucData.readyToSelectSub && this.props.player.ucData.startReady ? (
                        <>
                            <h1>waiting for players to pick...</h1>
                        </>
                    ) : (
                        <>
                                <div className="category-select-container-headers">
                                    <div className="category-select-user-icon"> </div>
                                    <h1>
                                    Hello, {this.state.player.name}
                                </h1>
                                <h2>
                                    Welcome to universally challenged! Please tell us a bit about yourself.
                                </h2>
                            </div>
                            {this.props.player.ucData.readyToSelectSub?  (
                                <>
                                    {this.state.geniusSubjects.map((subject, idx) => (
                                        <div className="category-select-subjects-container" key={idx}>
                                            <h2>What kind of {subject} are you into?</h2>
                                            <div className="category-select-genius-subjects">
                                                {['1', '2', '3', '4', '5', '6'].map(subCategory => (
                                                    <button
                                                        key={subCategory}
                                                        onClick={() => this.handleSubCategorySelection(subject, subCategory)}
                                                        className={this.state.subGeniusSubjects[subject]?.includes(subCategory) ? 'selected' : ''}
                                                    >
                                                        {subCategory}
                                                    </button>
                                                ))}
                                            </div>
                                            
                                        </div>
                                    ))}
                                </>
                            ) : (
                                <>
                                    <div className="category-select-user-age">
                                        <h2>Enter your age</h2>
                                        <input
                                            placeholder={this.state.playerAge}
                                            onChange={(e) => { this.setState({ playerAge: e.target.value }) }}
                                        />
                                    </div>
                                    <div className="category-select-subjects-container">
                                        <h2>Select exactly 3 subjects in which you think you are a genius at!</h2>
                                        <div className="category-select-flex-container">
                                            {['Music', 'Sport', 'History', 'Movies', 'TV', 'Maths', 'Science', 'Travel', 'Politics'].map(category => (
                                                <button
                                                    key={category}
                                                    onClick={() => this.handleCategorySelection(category)}
                                                    className={this.state.geniusSubjects.includes(category) ? 'selected' : ''}
                                                >
                                                    {category}
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                </>
                                )}
                                <div className="next-button-wrapper">
                                    <button className="next-button" onClick={() => { this.confirmSelection() }}>Next</button>

                                </div>
                        </>
                    )
                }

            </div>
        );
    }

}