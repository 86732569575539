import React, { Component, useRef, useEffect } from 'react';
import './HostGameView.scss';
import ConnectFour from './ConnectFour';
import MissingVowels from './MissingVowels';
import WhereInTheWorld from './WhereInTheWorld';
import LyricLinguist from './LyricLinguist';
import SpellingBee from './SpellingBee';
import PictureFrame from './PictureFrame';
import FaceOff from './FaceOff';
import SceneIt from './SceneIt'

import FooterToaster from './FooterToaster';
import HeaderToaster from './HeaderToaster';

const roundMapping = {
    'connect_four': 1,
    'where_in_the_world': 2,
    'lyric_linguist': 3,
    'spelling_bee': 4,
    'missing_vowels': 5,
    'picture_frame': 6,
    'face_off': 7,
    'scene_it': 8,
};

export default class GameView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            room: props.room,
            players: props.players,
            correctAnswer: props.correctAnswer,

            currentRound: props.round,
            nextRound: props.round,
            nextAnswer: props.correctAnswer,
            intermission: false,
            animationClass: '',
            animationClass2: '',
            showScores: false,
            scoreUpdated: false,
            scoreFadeOut: false,
            previousTeamAScore: props.teamAScore,
            previousTeamBScore: props.teamBScore,
            teamAScore: props.teamAScore,
            teamBScore: props.teamBScore,
            roundNumber: 1,

            //playAudio: false,
            //audioUrl: '',

            showWITWLeaderboard: false,
            showCorrectAnswer: false,
            footerDescription: 'The next round will come shortly'
        };

        this.sleepController = new AbortController();
    }

    sleep(ms) {
        return new Promise((resolve, reject) => {
            const onAbort = () => {
                clearTimeout(timeoutId)
                reject(new Error("Aborted"))
            }

            const timeoutId = setTimeout(() => {
                this.sleepController.signal?.removeEventListener("abort", onAbort)
                resolve()
            }, ms)

            this.sleepController.signal?.addEventListener("abort", onAbort)
        });
    }

    componentDidMount() {
        if (this.props.room) {
            this.props.room.onMessage("round_over", (message) => {
                this.doRoundOver(message);
            });
            this.props.room.onMessage("new_full_round", (message) => {
                this.endFullRound(message);
            });
            this.props.room.onMessage("all_players_submitted", (message) => {
               this.showAnswerEndOfRound()
            })
            this.props.room.onMessage("round_timer_ended", (message) => {
                this.showAnswerEndOfRound()
            });
            this.props.room.onMessage("start_playing_round", async (message) => {
                await this.sleep(500);
                this.setState({
                    animationClass: 'slideUpFadeIn',
                    showRound: true,
                });
            });
        }
    }

    async showAnswerEndOfRound() {
        if (!this.props.round !== "where_in_the_world") {
            this.setState({ showCorrectAnswer: true });
            await this.sleep(3000)
            this.setState({ showCorrectAnswer: false });
            this.props.room.send("handle_player_answers", { round: this.state.round })
        }
    }

    async doStartGame() {
        console.log("start game hit in client view");
        this.setState({ animationClass: '', intermission: true });
        this.playRoundIntroVO();
        await this.sleep(10000);
        this.setState({ animationClass: 'popOut' });
        await this.sleep(500);
        this.setState({ intermission: false, });
        await this.sleep(1000);
        this.setState({ animationClass: 'slideUpFadeIn', showRound: true,});
        this.props.room.send('start_playing')
        //this.props.audio.StageTimerSFX.loaded?.play();
    }

    doRoundOver(message) {
        if (this.props.round === 'where_in_the_world') {
            console.log("Small transition (stage change): 1 hit WHERE IN THE WORLD");
            setTimeout(() => { // timeout for the answer to be displayed
                this.setState(
                    {
                        animationClass: 'slideDownFadeOut',
                        showRound: false,
                        nextAnswer: this.props.correctAnswer,

                        //playAudio: false,
                        //audioUrl: 'https://big-potato-tv.s3.eu-west-2.amazonaws.com/UniversallyChallenged/GameAudio/StageTimer.mp3'
                    });
                //this.props.audio.StageTimerSFX.loaded?.stop();
                setTimeout(() => {
                    this.setState({ showWITWLeaderboard: true, animationClass2: 'popIn' });
                    setTimeout(() => {
                        this.setState({ animationClass2: 'popOut' });
                        setTimeout(() => {
                            this.setState({ showWITWLeaderboard: false });
                            setTimeout(() => {
                                this.state.room.send('new_round_started');
                            }, 500);
                        }, 500);
                    }, 2000);
                }, 1000);
            }, 8000);
        } else {
            console.log("Small transition (stage change): 1 hit");
            this.setState({
                animationClass: 'slideDownFadeOut',
                showRound: false,
                nextAnswer: this.props.correctAnswer,
            });

            console.log('Updating answer only, same round:', this.state.currentRound);
            setTimeout(() => {
                this.state.room.send('new_round_started');
            }, 1000);
        }
    }

    endFullRound(message) {
        console.log("Big intermission (round change): 2 hit");
        // MAIN ROUND TRANSITION
        this.setState(
            {
                animationClass: 'slideDownFadeOut',
                showRound: false,
                //playAudio: false,
                //audioUrl: '',
                previousTeamAScore: this.state.teamAScore,
                previousTeamBScore: this.state.teamBScore,
                teamAScore: this.props.teamAScore,
                teamBScore: this.props.teamBScore,
                nextRound: this.props.round,
                nextAnswer: this.props.correctAnswer,
            });
            //this.props.audio.StageTimerSFX.loaded?.stop();
        setTimeout(() => {
            this.setState(
                {
                    intermission: true,
                    currentRound: this.state.nextRound,
                    animationClass: 'hiddenAnim',
                    showScores: false,
                    scoreFadeOut: false,
                    scoreUpdated: false,
                    roundNumber: roundMapping[this.props.round] || 1,
                });
            setTimeout(() => {
                this.setState({ scoreUpdated: true, showScores: true, animationClass: "popIn" });
                setTimeout(() => {
                    this.setState({ scoreFadeOut: true });
                    setTimeout(() => {
                        this.playRoundIntroVO();
                        this.setState({ showScores: false, animationClass: 'slideUpFadeIn' });
                        setTimeout(() => {
                            this.setState({ animationClass: 'slideDownFadeOut' });
                            setTimeout(() => {
                                this.setState({ intermission: false, animationClass: 'hiddenAnim' });
                                setTimeout(() => {
                                    this.state.room.send('start_playing_round');
                                }, 1000);
                            }, 1000);
                        }, 5000)
                    }, 1500);
                }, 3000);
            }, 500);
        }, 1000);
    }

    playRoundIntroVO() {
        console.log("Playing round intro VO", "round: ", this.props.round );
        switch (this.props.round) {
            case 'connect_four':
                this.props.audio.ConnectFourIntroVO.loaded?.play();
                break;
            case 'missing_vowels':
                //this.props.audio.MissingVowelsIntroVO.loaded?.play();
                break;
            case 'where_in_the_world':
                this.props.audio.WITWIntroVO.loaded?.play();
                break;
            case 'lyric_linguist':
                //this.props.audio.LyricLinguistIntroVO.loaded?.play();
                break;
            case 'face_off':
                //this.props.audio.FaceOffIntroVO.loaded?.play();
                break;
            case 'scene_it':
                //this.props.audio.SceneItIntroVO.loaded?.play();
                break;
            default:
                break;
        }
    }

    renderTextDiv(text, additionalStyles) {
        return (
            <div className={`bold-text-outline ${this.state.animationClass}`}>
                {text}
                <div className="bold-text">{text}</div>
            </div>
        );
    }

    renderScores() {
        const {
            previousTeamAScore,
            previousTeamBScore,
            teamAScore,
            teamBScore,
            scoreUpdated,
            scoreFadeOut,
        } = this.state;

        const baseHeight = 5;
        const maxBarHeight = 200;
        const maxPossibleScore = 10;

        const teamAScoreToUse = scoreUpdated ? teamAScore : previousTeamAScore;
        const teamBScoreToUse = scoreUpdated ? teamBScore : previousTeamBScore;

        let teamAHeight = (teamAScoreToUse / maxPossibleScore) * maxBarHeight;
        let teamBHeight = (teamBScoreToUse / maxPossibleScore) * maxBarHeight;

        teamAHeight = Math.max(Math.min(teamAHeight, maxBarHeight), baseHeight);
        teamBHeight = Math.max(Math.min(teamBHeight, maxBarHeight), baseHeight);

        return (
            <div className={`score-popup popIn ${scoreFadeOut ? 'fadeOut' : ''}`}>
                    <div className="score-box">
                        <div className="score-box-team">
                        <div>
                            <h1>
                                Team A Score:
                            </h1>
                            <h2>
                                {this.props.teamAScore}
                            </h2>
                        </div>
                            {/*<div*/}
                            {/*    className="team-bar teamA-bar"*/}
                            {/*    style={{ height: `${teamAHeight}px` }}*/}
                            {/*/>*/}
                        </div>
                        <div className="score-box-team">
                        <div>
                            <h1>
                                Team B Score:
                            </h1>
                            <h2>
                                {this.props.teamBScore}
                            </h2>
                        </div>
                            {/*<div*/}
                            {/*    className="team-bar teamB-bar"*/}
                            {/*    style={{ height: `${teamBHeight}px` }}*/}
                            {/*/>*/}
                        </div>
                    </div>
             
            </div>
        );
    }


    getFooterDescription() {
        const { round } = this.props;
        switch (round) {
            case 'connect_four':
                return 'Find the connection between the following clues!';
            case 'missing_vowels':
                return 'Complete the words by entering the missing vowels!';
            case 'where_in_the_world':
                return 'Tap the location you see before you on the screen!';
            case 'lyric_linguist':
                return 'Name the song from the lyrics my friend will say!';
            case 'face_off':
                return 'Say who you see!';
            case 'scene_it':
                return "What movie has monet depicted?";
            default:
                return 'The next roudn will come shortly';

        }
    }


    getRenderView() {
        var { round, correctAnswer } = this.props;
        correctAnswer = correctAnswer.filter(x => x);
        let roundToDisplay;

        switch (round) {
            case 'connect_four':
                roundToDisplay = "Connect Four";
                break;
            case 'missing_vowels':
                roundToDisplay = 'Missing Vowels';
                break;
            case 'where_in_the_world':
                roundToDisplay = 'Where In The World';
                break;
            case 'lyric_linguist':
                roundToDisplay = 'Lyric Linguist';
                break;
            case 'spelling_bee':
                roundToDisplay = 'Spelling Bee';
                break;
            case 'picture_frame':
                roundToDisplay = 'Picture Frame';
                break;
            case 'face_off':
                roundToDisplay = 'Face Off';
                break;
            case 'scene_it':
                roundToDisplay = 'Scene It';
                break;
            default:
                roundToDisplay = 'Connect Four';
                break;

        }


        if (this.state.intermission) {
            return (
                <div className="intermission-container">

                    {this.state.showScores ? (
                        this.renderScores()
                    ) : (
                        <>
                            <div className="header-toaster-game popIn popInDelay1">
                                <HeaderToaster message={`Round ${this.state.roundNumber}`} />
                            </div>
                            <div className="popup-text-game popIn popInDelay2">
                                {this.renderTextDiv(roundToDisplay, '')}
                            </div>
                            <div className="footer-toaster-game popIn popInDelay3">
                                <FooterToaster message={this.getFooterDescription()} />
                            </div>
                        </>
                    )}
                </div>
            );
        } else {
            switch (round) {
                case 'connect_four':
                    return <ConnectFour room={this.props.room} players={this.props.players} ConnectFourData={this.props.ConnectFourData} correctAnswer={correctAnswer} timer={this.props.timer} isBuzzTimer={this.props.isBuzzTimer} showCorrectAnswer={this.state.showCorrectAnswer} hintsRevealed={this.props.hintsRevealed} />;
                case 'missing_vowels':
                    return <MissingVowels room={this.props.room} players={this.props.players} word={correctAnswer} MissingVowelsData={this.props.VanishingVowelsData} showCorrectAnswer={this.state.showCorrectAnswer} />;
                case 'where_in_the_world':
                    return <>
                        <WhereInTheWorld room={this.props.room} players={this.props.players} WhereInTheWorldData={this.props.WhereInTheWorldData} roundOver={this.props.roundOver} correctAnswer={correctAnswer} />;
                    </>
                case 'lyric_linguist':
                    return <LyricLinguist room={this.props.room} show={this.state.showRound} players={this.props.players} LyricLinguistData={this.props.LyricLinguistData} correctAnswer={correctAnswer} showCorrectAnswer={this.state.showCorrectAnswer} timer={this.props.timer}/>;


                case 'spelling_bee':
                    return <SpellingBee room={this.props.room} players={this.props.players} SpellingBeeData={this.props.SpellingBeeData} correctAnswer={correctAnswer} showCorrectAnswer={this.state.showCorrectAnswer} />;
                case 'picture_frame':
                    return <PictureFrame room={this.props.room} players={this.props.players} PictureFrameData={this.props.PictureFrameData} correctAnswer={correctAnswer} showCorrectAnswer={this.state.showCorrectAnswer} />;
                case 'face_off':
                    return <FaceOff room={this.props.room} players={this.props.players} FaceOffData={this.props.FaceOffData} correctAnswer={correctAnswer} showCorrectAnswer={this.state.showCorrectAnswer} />;
                case 'scene_it':
                    return <SceneIt room={this.props.room} players={this.props.players} SceneItData={this.props.SceneItData} correctAnswer={correctAnswer} showCorrectAnswer={this.state.showCorrectAnswer} />;
                default:
                    return <ConnectFour room={this.props.room} players={this.props.players} ConnectFourData={this.props.ConnectFourData} correctAnswer={correctAnswer} showCorrectAnswer={this.state.showCorrectAnswer} />;
            }
        }
    }

   

    getHeaderRoundRender() {
        const { round } = this.props

        switch (round) {
            case 'connect_four':
                return "What's the connection?"
            case 'missing_vowels':
                return `${this.props.VanishingVowelsData}`
            case 'where_in_the_world':
                if (this.props.WhereInTheWorldData && this.props.WhereInTheWorldData.length > 0) {

                    return `${this.props.WhereInTheWorldData.filter(x => x)[0]}`;
                } else {
                    return "Where In The World";
                }
            case 'lyric_linguist':
                return 'Name the song'
            case 'spelling_bee':
                return 'Spelling Bee'
            case 'picture_frame':
                return 'Picture Frame'
            case 'face_off': 
                return 'Who are we?'
            case 'scene_it': 
                return "What's the movie?"
            default:
                return 'PLAY!'
        }
    }

    render() {
        console.log("GameView Is Buzz Timer : ", this.props.isBuzzTimer)
        return (
            <>
                {
                    this.state.showWITWLeaderboard && (
                        <div className={`WITW-leaderboard-animation ${this.state.animationClass2}`}>
                            <WhereInTheWorldLeaderboard room={this.props.room} players={this.props.players} />

                        </div>
                    )
                }
                <div className={`animation-wrapper ${this.state.animationClass}`}>
                    <div className="game-view-wrapper">

                        {
                            <>
                                {
                                    !this.state.intermission && this.props.round !== 'lyric_linguist' &&  (
                                        <div className="header-toaster-in-game popIn popInDelay1">
                                            <HeaderToaster message={this.getHeaderRoundRender()} timer={this.props.timer} isBuzzTimer={this.props.isBuzzTimer} round={this.props.round} />
                                        </div>

                                    )
                                }
                                {
                                    this.getRenderView()
                                }
                                {/*<AudioPlayer url={this.state.audioUrl} playAudio={this.state.playAudio} round={this.props.round} />*/}
                            </>

                        }
                    </div>
                </div>
            </>

        );
    }
}

//const AudioPlayer = ({ url, playAudio, round }) => {
//    const audioRef = useRef(null);

//    useEffect(() => {
//        if (audioRef.current) {
//            if (playAudio) {
//                audioRef.current.play().catch((error) => {
//                    console.error('Autoplay failed:', error);
//                });
//            } else {
//                audioRef.current.pause();
//                audioRef.current.currentTime = 0;
//            }
//        }
//    }, [url, playAudio]);


//    if (round !== 'lyric_linguist') {
//        return (
//            <audio ref={audioRef} src={url} />
//        );
//    } else {
//        return <></>
//    }
//};

const WhereInTheWorldLeaderboard = ({ room, players }) => {
    const getColorForDistance = (distance) => {
        console.log(distance);
        return "white";

        if (distance < 50) {
            return 'green';
        } else if (distance < 100) {
            return 'yellow';
        } else if (distance < 200) {
            return 'orange';
        } else {
            return 'red';
        }
    };

    const getOrdinal = (n) => {
        let s = ["th", "st", "nd", "rd"];
        let v = n % 100;

        return (s[(v - 20) % 10] || s[v] || s[0]);
    };

    return (
        <div className="host-witw-leaderboard-container">
            {/* Sort by the ucData.whereInTheWorldDistance*/}
            {players.sort((a, b) => a.ucData.whereInTheWorldDistance - b.ucData.whereInTheWorldDistance).map((player, index) => {
                const distance = player.ucData.whereInTheWorldDistance;
                const color = getColorForDistance(distance);
                if (index > 2) return null;

                return (
                    <div
                        className="host-witw-leaderboard-player"
                        key={player.id || player.name}
                        style={{ color }}
                    >
                        <div className="host-witw-leaderboard-player-icon" >
                            <img src={player.ucData.playerAvatar} alt="Av" />
                        </div>
                        <h1>{player.name}</h1>
                        <h2>
                            {/*Show their ordinal position instead*/}
                            {index + 1}
                            <span>{getOrdinal(index + 1)}</span>
                            {/*{distance.toFixed(0)}*/}
                            {/*<span>km</span>*/}
                        </h2>
                    </div>
                );
            })}
        </div>
    );
};
