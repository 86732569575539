import http from "helpers/http.js";

const API_URL = process.env.REACT_APP_API_URL;

const createPaymentIntent = async (product, email) => {
    return await http.post(API_URL + "/api/payments/create-intent", {
        items: [product],
        promotionCode: "",
        email,
    });
};

const verifyAndGenerateActivationCode = async (paymentIntentId) => {
    return await http.post(API_URL + "/api/payments/verify-and-generate", {
        paymentIntentId
    });
}

const verifyAndRedeemCode = async (paymentIntentId) => {
    return await http.post(API_URL + "/api/payments/verify-and-redeem", {
        paymentIntentId
    });
}

const checkPromotionCode = async (promotionCode, productIds) => {
    return await http.post(API_URL + "/api/payments/check-promotion-code", {
        promotionCode,
        productIds,
    });
}

const getPricing = async () => {
    return await http.get(API_URL + "/api/payments/get-price");
}


const getIP = async () => {
    return await http.get(API_URL + "/api/payments/get-ip");
}

const getCountryCode = async () => {
    return await http.get(API_URL + "/api/payments/get-country-code");
}

export default {
    createPaymentIntent,
    verifyAndGenerateActivationCode,
    verifyAndRedeemCode,
    checkPromotionCode,
    getPricing,
    getIP,
    getCountryCode,
};