import React, { Component, createRef } from 'react';
import * as Colyseus from "colyseus.js";
import { Route } from 'react-router';
import { connect } from 'react-redux';
import QRCode from 'qrcode.react';
//import Lottie from 'react-lottie';
import { Howl, Howler } from "howler";
//import Confetti from 'react-confetti';
//import LoggingService from "services/logging";

import Loading from "components/Loading";
import Menu from "components/Menu";
import Player from "components/Player";
//import Tutorial from "components/Tutorial";

//import getAvatarById from "constants/avatars";

import "animate.css";
import styles from 'components/HostStyles.module.scss';

import logo from "images/logo.png";
import UCLogo from 'images/PlaceHolderLogo.png'
import mutedIcon from "images/Host/lobby_muted.png";
import unmutedIcon from "images/Host/lobby_unmuted.png";
import fullscreenIcon from "images/Host/lobby_fullscreen.png";
import helpIcon from "images/Host/lobby_help.png";

import CategorySelect from './HostComponents/CategorySelect';
import SelectingTeam from './HostComponents/SelectingTeam';
import GameView from './HostComponents/GameView';
import GameOver from './HostComponents/GameOver'
import DefaultView from './HostComponents/DefaultView';
import TeamWidgetContainer from './HostComponents/TeamWidgetContainer'
import HostSpeaking from './HostComponents/HostSpeaking'
import { withRouter } from 'react-router-dom';

import RoundIntroSFX from '../audio/RoundIntro.mp3';
import StageTimerSFX from '../audio/StageTimer.mp3';
import ConnectFourIntroVO from '../audio/Connection-Intro.mp3';
import WITWIntroVO from '../audio/WITW-Intro.mp3';
import GetIntoTeamsVO from '../audio/Get-Into-Teams.mp3';

const fullscreenAvailable = document.fullscreenEnabled || document.mozFullscreenEnabled || document.webkitFullscreenEnabled ? true : false;

var audio = {

    RoundIntroSFX: {
        import: RoundIntroSFX,
        loaded: null,
        volume: 0.15,
        loop: true,
    },
    StageTimerSFX: {
        import: StageTimerSFX,
        loaded: null,
        volume: 0.2,
        loop: false,
    },
    ConnectFourIntroVO: {
        import: ConnectFourIntroVO,
        loaded: null,
        volume: 1,
        loop: false,
    },
    WITWIntroVO: {
        import: WITWIntroVO,
        loaded: null,
        volume: 1,
        loop: false,
    },
    GetIntoTeamsVO: {
        import: GetIntoTeamsVO,
        loaded: null,
        volume: 1,
        loop: false,
    },
};


const GameStates = {
    Loading: "loading",
    Tutorial: "tutorial",
    Idle: "idle",
    SelectingInfo: "selecting_info",
    SelectingTeam: "selecting_team",
    Playing: "playing",
    GameOver: "game_over",
    EndGame: "end_game",
};

const RoundTypes = {
    Laoding: "loading",
    ConnectFour: "connect_four",
    MissingVowels: "missing_vowels",
    WhereInTheWorld: "where_in_the_world",
    LyricLinguist: "lyric_linguist",
    SpellingBee: "spelling_bee",
    PictureFrame: "picture_frame",
    FaceOff: "face_off",
    SceneIt: "scene_it",
};

const gameRoundStateVars = [
    "ConnectFourData",
    "WhereInTheWorldData",
    "SpellingBeeData",
    "PictureFrameData",
    "correctAnswer",
];
const gameId = "universally_challenged";


class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);

        this.client = new Colyseus.Client(process.env.REACT_APP_GAME_SERVER_URL);
        this.state = {
            roomId: 0,
            room: null,
            myId: null,
            roomState: null,
            redirect: null,
            reconnectTries: 0,
            connected: false,
            //connected: true, //Debug
            muted: false,
            menuOpen: false,
            tickedSkipTutorial: false,
            gameBegun: false, // test

            players: [],
            playersRequired: 1,
            showPlayers: true,
            gameState: GameStates.Loading,
            round: RoundTypes.Loading,

            doingTutorial: false,
            showTutorial: false,
            skipTutorialCount: 0,
            showStartButtons: true,
            showPlayAgainButtons: false,

            ConnectFourData: [],
            WhereInTheWorldData: [],
            LyricLinguistData: [],
            SpellingBeeData: [],
            PictureFrameData: [],

            showBlackScreen: false,
            moveLogoToTopLeft: false,
            showTeams: false,

            teamAScore: 0,
            teamBScore: 0

        };

        this.toggleMute = this.toggleMute.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.GameViewRef = createRef();

    }

    componentDidMount() {
        //this.doReconnect();

        this.toggleMute(true, false);
        this.initAudio();
        if (this.props.auth.isAuthorised) {
            this.startLobby();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.auth.isAuthorised && prevProps.auth.isAuthorised !== this.props.auth.isAuthorised) {
            this.startLobby();
        }
    }

    startLobby() {
        const token = this.getQueryStringValue("token");
        if (token) {
            this.doReconnect();
        } else {
            this.doCreate();
        }
    }

    initAudio() {
        console.log("Init audio");
        this.preloadAudio();
        Howler.volume(0.5);
        //this.playAudio(audio.BgMusic, true, 0.15);
    }

    preloadAudio() {
        for (let key in audio) {
            audio[key].loaded = new Howl({
                src: [audio[key].import],
                preload: true,
                loop: audio[key].loop,
                volume: audio[key].volume
            });
        }
    }

    playAudio(audio) {
        if (audio.loaded) audio.loaded.play();
    }

    toggleFullScreen() {
        if (fullscreenAvailable) {
            if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
                let elem = document.documentElement
                if (elem.requestFullscreen) {
                    elem.requestFullscreen();
                } else if (elem.webkitRequestFullscreen) {
                    elem.webkitRequestFullscreen();
                } else if (elem.mozRequestFullScreen) {
                    elem.mozRequestFullScreen();
                } else if (elem.msRequestFullscreen) {
                    elem.msRequestFullscreen();
                }
            } else {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                } else if (document.mozExitFullscreen) {
                    document.mozExitFullscreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            }
        }
    }

    getQueryStringValue(key) {
        return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[.+*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
    }

    animatePotato(id, animation) {
        if (document.getElementById(`potato-${id}`)) {
            this.animateCSS(`#potato-${id}`, animation);
        }
    }

    animateCSS = (element, animation, prefix = 'animate__') =>
        // We create a Promise and return it
        new Promise((resolve, reject) => {
            const animationName = `${prefix}${animation}`;
            let node;
            if (typeof element === `string`) {
                node = document.querySelector(element);
            } else {
                node = element;
            }
            node.classList.add(`${prefix}animated`, animationName);

            // When the animation ends, we clean the classes and resolve the Promise
            function handleAnimationEnd(event) {
                event.stopPropagation();
                node.classList.remove(`${prefix}animated`, animationName);
                resolve('Animation ended');
            }

            node.addEventListener('animationend', handleAnimationEnd, { once: true });
        });

    toggleMenu() {
        console.log("toggle menu");
        let newVal = this.state.menuOpen === true ? false : true;
        this.setState({ menuOpen: newVal });
    }

    toggleMute(force = false, value = false) {
        let newVal;
        if (force) {
            newVal = value;
        } else {
            newVal = !this.state.muted;
        }

        Howler.mute(newVal);
        this.setState({ muted: newVal, });
    }

    checkAndAddPlayer(player) {
        console.log("New Player with Id : ", player.id);
        if (!this.state.players.find(elem => elem.id === player.id)) {
            this.setState((prevState) => {
                return { players: [...prevState.players, player] }
            });
            console.log("NEW PLAYERS: ", this.state.players);
        }
    }

    removePlayer(id) {
        this.setState((prevState) => {
            return { players: prevState.players.filter(x => x.id !== id), }
        });
    }

    getRandomElement(array) {
        return array[Math.random() * array.length >> 0];
    }

    signalStartGame = () => {
        console.log("signalStartGame hit")
        this.setState({ showBlackScreen: true, moveLogoToTopLeft: true });

        setTimeout(() => {
            this.setState({ showStartButtons: false });
            this.state.room.send("begin_game", { skipTutorial: true, });
        }, 1500)


        setTimeout(() => {
            this.setState({ showBlackScreen: false });
        }, 3000);

    }
    goToLobby = () => {
        this.state.room.send("change_game", {});
    }
    signalNewGame = () => {
        this.setState({ showPlayAgainButtons: false, showWinnerSection: false, });
        setTimeout(() => {
            this.state.room.send("uc_new_game", {});
        }, 1000);
    }

    getRedirectURL(display = false) {
        let url = display ? process.env.REACT_APP_GAME_CITY_URL_DISPLAY : process.env.REACT_APP_GAME_CITY_URL;
        if (this.state.room) {
            if (this.state.room.name !== "game_city_room") {
                url = display ? process.env.REACT_APP_HOME_URL_DISPLAY : process.env.REACT_APP_HOME_URL;
            }
        }
        return url;
    }

    toggleSkipTutorial = (e) => {
        if (this.state.showStartButtons) {
            console.log("cb value: " + e.target.checked);
            this.setState({ tickedSkipTutorial: e.target.checked });
        }
    }

    updateToken(token) {
        var url = new URL(window.location.href);

        try {
            window.history.replaceState(null, null, (url.pathname) + (`?token=${token}`));
        } catch (e) {
            console.warn(e)
        }
    }

    setupStateListeners = (roomState) => {
        roomState.players.onAdd((player, key) => {
            console.log(JSON.stringify(player), "has been added at", key);
            this.checkAndAddPlayer(player);

            const changes = ["connected", "votedSkip", "avatar", "name", "id", "primaryPlayer", "connectingTimer"];
            changes.forEach(change => {
                player.listen(change, (value) => {
                    console.log(`Player ${player.name} Change ${change} to Value ${value}`);
                    this.setState((prevState) => {
                        return {
                            players: prevState.players.map((x) => {
                                if (x.id === player.id) {
                                    return { ...x, [change]: value };
                                }
                                return x;
                            })
                        }
                    });
                });
            });

            const ucPlayerChanges = ["hasSubmittedAnswer", "playerAvatar", "playerAge",
                "hasUploadedAvatar", "faceOffAvatar", "faceOffName",
                "readyToSelectSub", "startReady", "team", "starbucks",
                "answersCorrect", "submittedAnswer", "answerCorrect", "whereInTheWorldColor",
                "whereInTheWorldDistance"];
            ucPlayerChanges.forEach(change => {
                player.ucData.listen(change, (value) => {
                    this.setState((prevState) => {
                        return {
                            players: prevState.players.map((x) => {
                                if (x.id === player.id) {
                                    return { ...x, ucData: { ...x.ucData, [change]: value } };
                                }
                                return x;
                            })
                        }
                    });
                });
            });

            const ucPlayerArrayChanges = ["whereInTheWorldCoords"]
            ucPlayerArrayChanges.forEach(change => {
                player.ucData[change].onAdd((value, index) => {
                    console.log(`${player.id} WhereInTheWorldCoords Change ${value} at index ${index}`)
                    this.setState((prevState) => {
                        // overwrite the array at the index
                        let newArray = [...prevState.players.find(x => x.id === player.id).ucData[change]];
                        newArray[index] = value;
                        return {
                            players: prevState.players.map((x) => {
                                if (x.id === player.id) {
                                    return { ...x, ucData: { ...x.ucData, [change]: newArray } };
                                }
                                return x;
                            })
                        }
                    });
                })
                player.ucData[change].onRemove((value, index) => {
                    console.log(`${player.id} WhereInTheWorldCoords Removed ${value} at index ${index}`)
                    this.setState((prevState) => {
                        return {
                            players: prevState.players.map((x) => {
                                if (x.id === player.id) {
                                    return { ...x, ucData: { ...x.ucData, [change]: x.ucData[change].map((x, i) => { if (i === index) { return null } return x }) } };
                                }
                                return x;
                            })
                        }
                    });
                })
            });

        });

        roomState.players.onRemove((player, key) => {
            console.log(JSON.stringify(player), "has been removed at", key);
                this.removePlayer(player.id);
            });

        /* Example game state listeners */
        const ucStateVars = ["gameState", "round", "teamAScore", "teamBScore",
            "timer", "LyricLinguistData", "VanishingVowelsData",
            "FaceOffData", "SceneItData", "roundOver", "intermission",
            "answerType", "teamALocked", "teamBLocked", "buzzedInPlayerId",
            "teamACorrect", "teamBCorrect", "hintsRevealed", "isBuzzTimer", "buzzTimer"];
        ucStateVars.forEach((stateVar) => {
            roomState.ucData.listen(stateVar, (change) => {
                //stateVar !== "timer" && console.log(`UCData change: ${stateVar} --- ${change}`);
                let update = {};

                // we will update the game data aswell

                if (change === "selecting_team") {
                    this.setState({ showTeams: true })
                    update[stateVar] = change;
                    audio.GetIntoTeamsVO.loaded?.play();
                }

                if (change === "intermission") {
                    console.log("TEST FOR INTERMISSION")
                }

                else if (stateVar === "correctAnswer" && Array.isArray(change)) {
                    update[stateVar] = change.join(", "); // Example: converting the array to a comma-separated string
                } else {
                    stateVar !== "timer" && console.log(stateVar, change)
                    update[stateVar] = change;
                }

                if (stateVar === "timer") {
                    console.log("TIMER HIT : ", change);
                }

                this.setState(update);
            });
        });

        gameRoundStateVars.forEach(arrayName => {
            let update = {};
            update[arrayName] = [];
            this.setState(update);
            roomState.ucData[arrayName].onAdd((change, index) => {
                console.log(`UC Array ${arrayName} Change ${change} at index ${index}`);
                this.setState((prevState) => {
                    // overwrite the array at the index
                    let newArray = [...prevState[arrayName]];
                    newArray[index] = change;
                    return {
                        [arrayName]: newArray
                    }
                });
            });
            roomState.ucData[arrayName].onRemove((change, index) => {
                console.log(`UC Array ${arrayName} Removed ${change} at index ${index}`);
                // set index to null
                this.setState((prevState) => {
                    return {
                        [arrayName]: prevState[arrayName].map((x, i) => {
                            if (i === index) {
                                return null;
                            }
                            return x;
                        })
                    }
                });
            });
        });
    }
    // gather all room states and room info and game info to resume where you left off in the doCatchUp

    doCatchUp = () => {
        console.log(this.state.gameState)
    }

    doReconnect = () => {
        const token = this.getQueryStringValue("token");
        console.log("Token:", token);

        if (this.state.reconnectTries < 5 && token) {
            //const roomId = this.getQueryStringValue("roomId");
            //const sessionId = this.getQueryStringValue("sessionId");
            //console.log(roomId);
            //console.log(sessionId);
            console.log("Connecting to room:", token)

            if (this.state.connected === false) {
                this.client.reconnect(token).then(room => {
                    this.initRoom(room);
                }).catch(e => {
                    console.log("JOIN ERROR", e);
                    this.setState({ connected: false, reconnectTries: this.state.reconnectTries + 1 });
                    setTimeout(() => {
                        this.doReconnect();
                    }, 1000);
                });
            }
        } else {
            this.setState({ redirect: `${this.getRedirectURL()}${this.state.roomState?.isRoku ? "/roku" : "/lobby"}` }) //Might need aditional search query url for roku incase the roomstate is null
            if (this.locationCheckInterval) clearInterval(this.locationCheckInterval);
        }
    }

    doCreate(force = false) {
        // this is the function that creates the room
        if (this.state.connected === false || force) {
            this.client.create("game_city_room").then(room => {
                this.initRoom(room);
            }).catch(e => {
                console.log("CREATE ERROR", e);
                this.setState({ connected: false, reconnectTries: this.state.reconnectTries + 1 });
                setTimeout(() => {
                    this.doCreate();
                }, 1000);
            });
        }
    }

    initRoom(room) {
        console.log(room.sessionId, "joined", room.name);
        this.setState({ room: room, roomId: room.id, myId: room.sessionId, connected: true, reconnectionToken: room.reconnectionToken });
        this.updateToken(room.reconnectionToken);
        room.send("update_host_token", { reconnectionToken: room.reconnectionToken });

        room.onStateChange.once((roomState) => {
            console.log("this is the first room state!", roomState);
            if (roomState.host.id !== room.sessionId) window.location = this.getRedirectURL();
            this.setState({ roomState: roomState, });
            this.doCatchUp();
            // this casues everyone to go back to lobby if the host has refreshed their page mid game
            //if (roomState.ucData.gameState === GameStates.Loading) {
            room.send("host_joined_game", { gameId });
            this.setupStateListeners(roomState);
            //}
            //} else {
            //    room.send("change_game", {});
            //}
        });

        room.onStateChange((state) => {
            //console.log(room.name, "has new state:", state);
            this.setState({ roomState: state, });
        });

        room.onMessage("animate_potato", (message) => {
            console.log("animate_potato", "received on", room.name, message);
            this.animatePotato(message.id, message.animation);
        });

        room.onMessage("start_game", (message) => {
            console.log("Start Game Hit")
            console.log("hello hello hello")

            room.send("start_round", {})
        })

        room.onMessage("begin_tutorial", (message) => {
            console.log("begin_tutorial", "received on", room.name, message);
            this.setState({ showTutorial: true, showStartButtons: false, showPlayers: false, });
            this.state.room.send("show_tutorial", {});
        });

        room.onMessage("end_tutorial", (message) => {
            console.log("end_tutorial", "received on", room.name, message);
            this.setState({ showTutorial: false, showPlayers: true, });
        });

        room.onMessage("clicked_begin_game", (message) => {
            this.setState({ showStartButtons: false, });
        });

        room.onMessage("begin_game", (message) => {
            console.log("begin_game", "received on", room.name, message);
            console.log("game state... ", this.state.roomState.ucData.gameState);
            //if ((this.state.roomState.ucData.gameState === GameStates.Loading || this.state.roomState.ucData.gameState === GameStates.Idle) && !this.state.gameBegun) {
            console.log("begin_game checks passed.")
            this.setState({ showTutorial: false, gameBegun: true, showStartButtons: false, });
            room.send("start_uc_game", {});
            //}
        });

        room.onMessage("game_over", (message) => {
            console.log("game_over", "received on", room.name, message);

        });
        room.onMessage("game_restarted", (message) => {
            console.log("restart game hit")
            this.setState({
                showStartButtons: true, gameBegun: false, showBlackScreen: false, moveLogoToTopLeft: false
            })
        })

        room.onMessage("change_game", (message) => {
            console.log("change_game", "received on", room.name, message);
            this.setState({ redirect: `${this.getRedirectURL()}${this.state.roomState.isRoku ? "/roku" : "/lobby"}/?token=${this.state.reconnectionToken}` });
            if (this.locationCheckInterval) clearInterval(this.locationCheckInterval);
            this.state.room.leave(false);
        });

        room.onMessage("new_game", (message) => {
            console.log("new_game", "received on", room.name, message);

        });

        room.onMessage("all_players_ready", (message) => {
            console.log("all_players_ready")
        })

        room.onMessage('all_players_in_team', (message) => {
            room.send('start_team_timer');
        })

        room.onMessage('end_team_selection', (message) => {
            console.log("end_team_selection hit in home");
            this.setState({}, () => {
                console.log("gameView", this.GameViewRef.current);
                this.GameViewRef.current?.doStartGame();
            });
        });


        room.onMessage("update_scores", (message) => {
            //this.setState({ teamAScore: message.teamAScore, teamBScore: message.teamBScore })
            console.log("TEAM SCORES", message)
            room.send("next_round", { round: this.state.round })
        })

        room.onError((code, message) => {
            console.log(this.client.id, "couldn't join", room.name);
            //LoggingService.logError(message, code);
        });

        room.onLeave((code) => {
            console.log(this.client.id, "left", room.name);
            if (!this.state.redirect) {
                this.setState({ connected: false, reconnectTries: this.state.reconnectTries + 1 });
                setTimeout(() => {
                    this.doReconnect();
                }, 1000);
            }
        });
    }

    getRenderView() {

        switch (this.state.gameState) {
            case 'uploading_avatar':
                return this.getHostSpeakingView(this.state.gameState);
            case 'selecting_info':
                return this.getUserCategoryView(this.state.gameState);
            case 'selecting_team':
                return this.getSelectingTeamView(this.state.gameState);
            case 'playing':
                return this.getGameView(this.state.gameState)
            case 'host_speaking':
                return this.getHostSpeakingView(this.state.gameState);
            case 'game_over':
                return this.getGameOverView(this.state.gameState);
            default:
                return this.getHostSpeakingView(this.state.gameState);
            //default:
            //    return this.getGameView()
        }
    }

    getUserCategoryView(gameState) {
        console.log(gameState)
        return <HostSpeaking room={this.state.room} players={this.state.players} gameState={gameState} />
    }

    getHostSpeakingView(gameState) {
        console.log(gameState)

        return <HostSpeaking room={this.state.room} players={this.state.players} gameState={gameState} />
    }

    getSelectingTeamView(gameState) {
        console.log(gameState)

        return <HostSpeaking room={this.state.room} players={this.state.players} gameState={gameState} timer={this.state.timer} textOverride={this.state.timer && this.state.timer !== 0 ? "Get Ready!" : ""} />
    }

    getGameOverView(gameState) {
        return <GameOver room={this.state.room} players={this.state.players} gameState={gameState} teamAScore={this.state.teamAScore} teamBScore={this.state.teamBScore} round={this.state.round } />
    }
    getGameView(gameState) {
        return (
            <>
                <GameView ref={this.GameViewRef} room={this.state.room} audio={audio} timer={this.state.timer} players={this.state.players}
                    round={this.state.round} ConnectFourData={this.state.ConnectFourData}
                    WhereInTheWorldData={this.state.WhereInTheWorldData} LyricLinguistData={this.state.LyricLinguistData}
                    SpellingBeeData={this.state.SpellingBeeData} VanishingVowelsData={this.state.VanishingVowelsData}
                    PictureFrameData={this.state.PictureFrameData} FaceOffData={this.state.FaceOffData} SceneItData={this.state.SceneItData}
                    correctAnswer={this.state.correctAnswer} teamAScore={this.state.teamAScore} teamBScore={this.state.teamBScore}
                    roundOver={this.state.roundOver} teamACorrect={this.state.teamACorrect} teamBCorrect={this.state.teamBCorrect}
                    hintsRevealed={this.state.hintsRevealed} isBuzzTimer={this.state.isBuzzTimer} buzzTimer={this.state.buzzTimer}
                />
                <HostSpeaking room={this.state.room} players={this.state.players} gameState={gameState}  />
            </>
        )

    }

    getDefaultView() {
        return <DefaultView room={this.state.room} players={this.state.players} />
    }

    closeMenu() {
        this.setState({menuOpen: false})
    }

    render() {

        const allPlayersConnected = this.state.players.every(player => player.connected);
        const minPlayersFullfilled = this.state.players.length >= this.state.playersRequired;
        const allPlayersHaveAvatar = this.state.players.every(player => player.ucData?.hasUploadedAvatar);

        /* commented out for dev (this block handles the redirecting away from the game) */
        //if (this.state.redirect) {
        //    return (
        //        <React.Fragment>
        //            <div id="gameContainer" className={styles.gameContainer}>
        //                <div className={styles.loadingContainer}>
        //                    <Loading loadingText={"Sending you to the lobby!"} />
        //                </div>
        //            </div>
        //            <div style={{ opacity: 0 }}>
        //                <Route path="/" render={() => (window.location = this.state.redirect)} />
        //            </div>'
        //        </React.Fragment>
        //    )
        //}

        return (
            <div>
                <audio ref />
                <div
                    id="gameContainer"
                    className={`${styles.gameContainer}`}
                >
                    <div className={`${!this.state.showStartButtons ? styles.mainBackground : styles.menuBackground}`} >
                        {
                            !this.state.showStartButtons ? (
                                <div className={styles.backgroundShadow} />
                            ) : null
                        }
                    </div>
                    {this.state.showBlackScreen && <div className={styles.blackScreenOverlay} />}
                    {
                        this.state.connected && (
                            <img
                                src={UCLogo}
                                className={`${styles.UCLogo} ${this.state.moveLogoToTopLeft && styles.UCLogoMoveToTopLeft}`}
                                alt="logo"
                            />
                        )
                    }
                    { window.location.hostname === "localhost" ?
                        <div className="test-buttons" >
                            <button onClick={() => this.doCreate(true)}>new lobby</button>
                            <button onClick={() => this.props.onLogout()}>log out</button>
                            <span style={{ padding: '0 1vh' }} />
                            <button onClick={() => this.state.room.send("next_round", { round: this.state.round })}>next round</button>
                            <button onClick={() => this.state.room.send("change_state", { data: "host_speaking" })}>host_speaking</button>
                            <button onClick={() => this.state.room.send("change_state", { data: "uploading_avatar" })}>upload_avatar</button>
                            <button onClick={() => this.state.room.send("change_state", { data: "selecting_info" })}>selecting_info</button>
                            <button onClick={() => this.state.room.send("change_state", { data: "selecting_team" })}>selecting_team</button>
                            <button onClick={() => this.state.room.send("change_state", { data: "playing" })}>playing</button>
                            <button onClick={() => this.state.room.send("change_state", { data: "idle" })}>idle</button>
                            <button onClick={() => this.state.room.send("change_state", { data: 'game_over' })} >End</button>
                            <span style={{ padding: '0 1vh' }} />
                            <button onClick={() => this.state.room.send("set_round", { data: "connect_four" })}>connectFour</button>
                            <button onClick={() => this.state.room.send("set_round", { data: "missing_vowels" })}>missingVowels</button>
                            <button onClick={() => this.state.room.send("set_round", { data: "where_in_the_world" })}>whereInTheWorld</button>
                            <button onClick={() => this.state.room.send("set_round", { data: "lyric_linguist" })}>lyricLinguist</button>
                            <button onClick={() => this.state.room.send("set_round", { data: "face_off" })}>faceOff</button>
                            <button onClick={() => this.state.room.send("set_round", { data: "scene_it" })}>sceneIt</button>
                        </div>
                        :
                        <div className="test-buttons" >
                            <button onClick={() => this.doCreate(true)}>new lobby</button>
                            <button onClick={() => this.props.onLogout()}>log out</button>
                        </div>
                    }
                    {
                        !this.state.connected ?
                            <div className={styles.logoSection}>
                                <img src={logo} className={styles.logo} alt="logo" />
                                <h1>UniversallyChallenged</h1>
                            </div>
                            :
                            <React.Fragment>
                                <Menu room={this.state.room} toggleMute={this.toggleMute} toggleMenu={this.toggleMenu} menuOpen={this.state.menuOpen} muted={this.state.muted} closeMenu={() => this.closeMenu()} />
                                {/*{*/}
                                {/*    this.state.showTutorial &&*/}
                                {/*    <Tutorial room={this.state.room} players={this.state.players} />*/}
                                {/*}*/}
                                {/*<div style={{ zIndex: 20, }}>*/}
                                {/*    {*/}
                                {/*        this.state.doConfetti &&*/}
                                {/*        <Confetti*/}
                                {/*            width={window.innerWidth}*/}
                                {/*            height={window.innerHeight}*/}
                                {/*            initialVelocityY={20}*/}
                                {/*            numberOfPieces={1500}*/}
                                {/*            recycle={false}*/}
                                {/*            confettiSource={{ x: window.innerWidth / 2, y: window.innerHeight + 10, width: window.innerWidth + 10, height: 0 }}*/}
                                {/*            initialVelocityY={{ min: -10, max: -30, }}*/}
                                {/*            initialVelocityX={{ min: -10, max: 10, }}*/}
                                {/*            onConfettiComplete={() => this.setState({ doConfetti: false, })}*/}
                                {/*        />*/}
                                {/*    }*/}
                                {/*</div>*/}
                                <div className={styles.roomCode}>
                                    <div className={styles.textBox}>
                                        <div className={styles.text}>{this.getRedirectURL(true)}</div>
                                        <div className={styles.text}>Code: <a href={`https://${window.location.host}/client/?qrCode=${this.state.roomId}`} className={`${styles.text} ${styles.code}`} target="_blank" rel="noopener noreferrer" >{this.state.roomId}</a></div>
                                        <div className={styles.iconsBox}>
                                            <div className={styles.muteToggle} onClick={() => this.toggleMute()}>
                                                <img src={this.state.muted ? mutedIcon : unmutedIcon} className={styles.muteIcon} alt="mute" />
                                            </div>
                                            {
                                                fullscreenAvailable ?
                                                    <div className={styles.muteToggle} onClick={() => { this.toggleFullScreen() }}>
                                                        <img src={fullscreenIcon} className={styles.muteIcon} alt="fullscreen" />
                                                    </div>
                                                    :
                                                    null
                                            }
                                            <div className={styles.muteToggle} onClick={() => this.toggleMenu()}>
                                                <img src={helpIcon} className={styles.muteIcon} alt="help" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.qrCodeBox}>
                                        <QRCode
                                            className={styles.qrCode}
                                            value={`https://${window.location.host}/client/?qrCode=${this.state.roomId}`}
                                            bgColor="rgba(0,0,0,0)"
                                        />
                                    </div>
                                </div>
                                <div className={`${styles.logoSection} ${this.state.connected ? styles.topLeft : ""}`}>
                                    <img src={logo} className={`${styles.logo}`} alt="logo" />
                                </div>
                                {
                                    this.state.showStartButtons &&
                                    <>
                                    <div className={styles.startButtonSection}>
                                            <div className={`${styles.mainButtonWrapper} ${(!allPlayersConnected || !minPlayersFullfilled || !allPlayersHaveAvatar) && styles.disabled}`}>
                                                <button className={`${styles.mainButton}`} onClick={this.signalStartGame} disabled={!allPlayersConnected || !minPlayersFullfilled || !allPlayersHaveAvatar} >
                                                Start Game
                                            </button>
                                        </div>
                                        {/*<div className={styles.mainButtonWrapper} >*/}
                                        {/*<button className={`${styles.mainButton} ${styles.smaller}`} onClick={this.goToLobby}>*/}
                                        {/*    Go To Lobby*/}
                                        {/*</button></div>*/}
                                        </div>
                                        <div className={styles.playerList}>
                                            {this.state.players.map(player => (
                                                <div className={styles.player} >
                                                    {
                                                        player.ucData.playerAvatar.length > 2 ?
                                                            <>
                                                                <img src={player.ucData.playerAvatar} alt="Avatar" className={styles.image} />
                                                            </> :
                                                            <>
                                                                <div className={styles.image} >
                                                                    <div class="circle-loading" />
                                                                </div>
                                                            </>
                                                    }
                                                    {!player.connected && <h3 className={styles.reconectionTimmer} >{player.connectingTimer}</h3>}
                                                    <div className={styles.name}>
                                                        <h3>
                                                            {player.connected? player.name : "Disconnected"}
                                                        </h3>
                                                    </div>
                                                </div>

                                            ))}
                                        </div>
                                    </>
                                }
                                <div className={`${styles.playerColumn} ${styles.left} ${this.state.showPlayers && styles.show}`}>
                                    {
                                        this.state.players.filter((x, index) => index % 2 === 0).map((x) => {
                                            return <Player player={x} left={true} />
                                        })
                                    }
                                </div>
                                <div className={`${styles.playerColumn} ${styles.right} ${this.state.showPlayers && styles.show}`}>
                                    {
                                        this.state.players.filter((x, index) => index % 2 === 1).map((x, index) => {
                                            return <Player player={x} />
                                        })
                                    }
                                </div>
                                {
                                    this.state.showPlayAgainButtons &&
                                    <div className={styles.playAgainButtonSection}>
                                        <button className={`${styles.button}`} onClick={this.signalNewGame}>
                                            Play Again
                                        </button>
                                        {/*<button className={`${styles.button}`} onClick={this.goToLobby}>*/}
                                        {/*    Return to lobby*/}
                                        {/*</button>*/}
                                    </div>
                                }
                                {
                                    !this.state.showStartButtons && (
                                        <div className="host-main-game-container">
                                            {
                                                !this.state.showStartButtons && this.state.showTeams && this.state.gameState !== 'game_over' ? (
                                                    <TeamWidgetContainer room={this.state.room} players={this.state.players}
                                                        teamAScore={this.state.teamAScore} teamBScore={this.state.teamBScore}
                                                        round={this.state.round} buzzedInPlayerId={this.state.buzzedInPlayerId}
                                                        answerType={this.state.answerType} teamALocked={this.state.teamALocked}
                                                        teamBLocked={this.state.teamBLocked} teamACorrect={this.state.teamACorrect}
                                                        teamBCorrect={this.state.teamBCorrect} />
                                                ) : null
                                            }
                                            {this.getRenderView()}
                                        </div>

                                    )
                                }
                            </React.Fragment>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(withRouter(Home));


