import React, { Component } from 'react';
import './GameView.scss';

export default class WordLetters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            answer: props.answer || '',
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.answer !== prevProps.answer) {
            this.setState({ answer: this.props.answer });
        }
    }

    splitIntoLines(text, maxCharsPerLine) {
        if (text.length > 0) {
            const words = text.split(' ');
            const lines = [];
            let currentLine = '';

            for (let word of words) {
                const appended = currentLine + (currentLine ? ' ' : '') + word;
                if (appended.length > maxCharsPerLine) {
                    lines.push(currentLine);
                    currentLine = word;
                } else {
                    currentLine = appended;
                }
            }

            if (currentLine) {
                lines.push(currentLine);
            }

            return lines;
        }
        return [];
    }

    render() {
        const { round, userInput } = this.props;
        const maxCharsPerLine = 12;

        const lines = this.splitIntoLines(this.state.answer, maxCharsPerLine);

        let userInputIndex = 0;
        let vowelInputIndex = 0;

        return (
            <div className="word-letters-wrapper">
                <div className="word-letters-container">
                    {lines.map((line, lineIndex) => (
                        <div key={lineIndex} className="word-letters-line">
                            {line.split('').map((letter, charIndex) => {
                                if (letter === ' ') {
                                    return (
                                        <div
                                            key={`${lineIndex}-${charIndex}`}
                                            className="space-placeholder"
                                        ></div>
                                    );
                                }

                                const isVowel = /[aeiouAEIOU]/.test(letter);

                                while (userInput[userInputIndex] === ' ') {
                                    userInputIndex++;
                                }

                                const userChar = userInput[userInputIndex] || '';
                                const hightlight = round === 'missing_vowels' ? isVowel && userInput.length === vowelInputIndex : userInput.length === userInputIndex;
                                userInputIndex++;
                                return (
                                    <div
                                        key={`${lineIndex}-${charIndex}`}
                                        className={`user-input-character ${hightlight && "highlight"}`}
                                    >
                                        {round === 'missing_vowels' ? (
                                            <h1>{isVowel ? userInput[vowelInputIndex++] : this.props.answer[charIndex]}</h1>
                                        ) : (
                                            <h1>{userChar}</h1>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}
