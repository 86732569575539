import React, { Component, useState, useEffect } from 'react';
import './HostGameView.scss';

export default class MissingVowels extends Component {
    constructor(props) {
        super(props);
        this.state = {
            room: props.room,
            players: props.players,
            word: props.word || '',
            MissingVowelsData: props.MissingVowelsData || ''
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.word !== this.props.word || prevProps.MissingVowelsData !== this.props.MissingVowelsData) {
            this.setState({
                word: Array.isArray(this.props.word) ? this.props.word.filter(x => x)[0] : this.props.word, 
                MissingVowelsData: this.props.MissingVowelsData 
            }, () => {
                console.log('Updated word and MissingVowelsData:', this.state.word, this.state.MissingVowelsData);
            });
        }
    }

    render() {
        return (
            <div className="host-missing-vowels-container">
                <MissingVowelsLetterBox word={this.state.word} showCorrectAnswer={this.props.showCorrectAnswer} />

                {/*<div className="host-missing-vowels-subject-container">*/}
                {/*    <h1>The Subject Is...</h1>*/}
                {/*    <div className="host-missing-vowels-subject-box">*/}
                {/*        <h2>{this.state.MissingVowelsData}</h2> */}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        );
    }
}

const MissingVowelsLetterBox = ({ word, showCorrectAnswer }) => {
    const [splitWord, setSplitWord] = useState([]);
    const vowels = ['a', 'e', 'i', 'o', 'u'];

    useEffect(() => {
        const formattedAnswer = Array.isArray(word) ? word.join('') : word;
        const slicedWord = typeof formattedAnswer === 'string' ? formattedAnswer.split('') : [];
        setSplitWord(slicedWord);
        
    }, [word]);

    const renderTextDiv =(text, additionalStyles) => {
        return <>
            <div className={`bold-text-outline extra-bold`}>{text}
                <div className={'bold-text'}>{text} </div>
            </div>
        </>
    }

    return (
        <div className="host-missing-vowels-letter-box-container">
            {splitWord.map((letter, index) => (
                <div key={index} className={`missing-vowels-letter ${letter === ' ' ? 'missing-vowels-space' : ''}`}>
                    <h2>
                        {
                            showCorrectAnswer ? (
                                renderTextDiv(letter)
                            ) : (
                                !vowels.includes(letter.toLowerCase()) && renderTextDiv(letter)
                            )
                        }
                    </h2>
                    <div className="underline-placeholder"/>
                </div>
            ))}
        </div>
    );
};
