import React, { Component } from 'react';
import FormPage from './Utility/FormPage';
import LoginForm from './Forms/Login';
import RegisterForm from './Forms/Register';
import Home from './Home';
import { connect } from 'react-redux';
import { withRouter } from './withRouter';
import AuthService from '../services/auth';
import Checkout from './Checkout';
import { authoriseUser, logout, autoLoginQRSession } from "actions/auth";
class LandingPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isRegister: false,
            isLoggedIn: this.props.auth.isLoggedIn,
            checkingGames: true,
            QRSessionID: new URLSearchParams(window.location.search).get("QRSessionID"),
        };
        this.loginChecks = null;
    }

    componentDidMount() {
        if (this.props.auth.isLoggedIn) {
            if (this.state.QRSessionID) this.authoriseQRSession(this.state.QRSessionID);
            else this.getGames();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.auth.isLoggedIn !== this.props.auth.isLoggedIn) {

            if (this.props.auth.isLoggedIn) {
                this.getGames();
            }
        }
    }

    goToRegister = () => {
        this.setState({ isRegister: true, })
    }

    goToLogin = () => {
        this.setState({ isRegister: false, })
    }

    getGames = () => {
        console.log("Get Games with Email : ", this.props.auth.user?.email);
        AuthService.getGames(this.props.auth.user?.email).then((res) => {
            console.log("getGames", res);
            if (res.games.length > 0) {
                this.props.authoriseUser();
            } else {
                this.setState({ checkingGames: false, });
            }
        }).catch((err) => {
            console.log("getGames error", err);
            this.setState({ checkingGames: false, });
        });
    }

    authoriseQRSession = async (QRSessionID) => {        
        const UserID = this.props.auth.user.id;
        this.setState({ loading: true });
        await this.props.autoLoginQRSession(QRSessionID, UserID);
        this.setState({ loading: true });
        this.props.navigate(`/client`);
    }

    onQRLogin = () => {
        //window.location.reload();
        //this.props.navigate("/");
        //this.setState({ checkingGames: true, }, ()=> this.getGames());
    }

    render() {
        return (
            <>
                {
                    !this.props.auth.isLoggedIn ?
                        <FormPage>
                            {
                                this.state.isRegister ?
                                    <RegisterForm goToLogin={this.goToLogin} goToRegister={this.goToRegister} QRSessionID={this.state.QRSessionID} />
                                    :
                                    <LoginForm onQRLogin={this.onQRLogin} goToLogin={this.goToLogin} goToRegister={this.goToRegister} QRSessionID={this.state.QRSessionID} />
                            }
                        </FormPage>
                        :
                        !this.props.auth.isAuthorised ?
                            <FormPage>
                                {
                                    this.state.checkingGames ?
                                        <div className="text-center">
                                            <h1>Checking Games</h1>
                                        </div>
                                        :
                                        <Checkout />
                                }
                            </FormPage >
                            :
                            null
                }
                {!this.state.QRSessionID &&
                    <Home onLogout={() => this.props.logout()} />}
            </>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        authoriseUser: () => {
            return dispatch(authoriseUser())
        },
        logout: () => {
            return dispatch(logout())
        },
        autoLoginQRSession: (QRSessionID, UserID) => {
            return dispatch(autoLoginQRSession(QRSessionID, UserID))
        }
    };
}



export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LandingPage));