import React, { Component } from 'react';
import { Route } from 'react-router';
import axios from 'axios';
import * as Colyseus from "colyseus.js";
//import Lottie from 'react-lottie';
import nosleep from 'nosleep.js';
import "animate.css";
import { Container, Spinner, Form, Alert } from 'react-bootstrap';

import Loading from "components/Loading";
import DefaultView from "components/DefaultView";
import UploadAvatar from 'components/UserComponents/UploadAvatar'
import CategorySelect from 'components/UserComponents/CategorySelect'
import SelectingTeam from 'components/UserComponents/SelectingTeam'
import GameView from 'components/UserComponents/GameView'
import Intermission from 'components/UserComponents/Intermission'
import styles from 'components/ClientStyles.module.scss';
import FormPage from './Utility/FormPage';

import formStyles from "components/Utility/FormStyles.module.scss"

var noSleep = new nosleep();
//var supportsVibrate = "vibrate" in navigator;

//const GameStates = {
//    // your game states here (identical to server)
//    Tutorial: "tutorial",
//};

const gameId = "universally_challenged";



const gameRoundStateVars = [
    "ConnectFourData",
    "WhereInTheWorldData",
    "SpellingBeeData",
    "PictureFrameData",
    "LyricLinguistData",
    "correctAnswer",
];

export class Client extends Component {
    static displayName = Client.name;

    constructor(props) {
        super(props);

        this.client = new Colyseus.Client(process.env.REACT_APP_GAME_SERVER_URL);
        this.state = {
            roomId: "",
            room: null,
            myId: null,
            roomState: null,
            isPaused: false,
            templateData: null,
            errorMessage: "",
            error: false,
            player: {
                ucData: {}
            },
            showDefaultView: true,
            doingTutorial: false,
            players: [],
            round: null,
            correctAnswer: [],
            intermission: false,

            WhereInTheWorldData: [],
            LyricLinguistData: [],
            loading: false,
            joinButtonValue: "JOIN"
        };
        this.locationCheckInterval = null;
        this.cancelToken = null;
    }

    componentDidMount() {
        const queryParams = new URLSearchParams(window.location.search);
        if (queryParams.has("token")) { 
            const token = queryParams.get("token");

            this.doReconnect(token);
        } else if (queryParams.has("qrCode")) {
            const roomId = queryParams.get("qrCode");
            this.setState({ roomId });
            this.checkGameExists(roomId);
            //let options = {};
            //let name = this.state.nickname.length > 0 ? this.state.nickname : "PLAYER";
            //options.name = name;
            //this.doJoin(queryParams.get("qrCode"), options);
        }

        document.addEventListener('click', function enableNoSleep() {
            document.removeEventListener('click', enableNoSleep, false);
            noSleep.enable();
        }, false);
    }

   

    getQueryStringValue(key) {
        return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[.+*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
    }

    startLocationChecks() {
        this.state.room.send("location_check", { gameId, });
        this.locationCheckInterval = setInterval(() => {
            this.state.room.send("location_check", { gameId, });
        }, 10000);
    }

    skipTutorial() {
        this.state.room.send("vote_skip");
        this.setState({ doingTutorial: false });
    }

    //goToLobby() {
    //    this.setState({ redirectURL: `${this.getRedirectURL()}?token=${this.state.reconnectionToken}` });
    //    this.state.room.leave(false);
    //    if (this.locationCheckInterval) clearInterval(this.locationCheckInterval);
    //}

    //getRedirectURL(display = false) {
    //    let url = display ? process.env.REACT_APP_GAME_CITY_URL_DISPLAY : process.env.REACT_APP_GAME_CITY_URL;
    //    if (this.state.room) {
    //        if (this.state.room.name !== "game_city_room") {
    //            url = display ? process.env.REACT_APP_HOME_URL_DISPLAY : process.env.REACT_APP_HOME_URL;
    //        }
    //    }
    //    return url;
    //}

    checkAndAddPlayer(player) {
        console.log("this.state === : ", this.state)
        if (!this.state.players.find(elem => elem.id === player.id)) {
            this.setState((prevState) => {
                return { players: [...prevState.players, player] }
            });

            // These are for syncing up some needed vars for all players for face off round
            const changes = ["name", "id", "primaryPlayer"];
            changes.forEach(change => {
                player.listen(change, (value) => {
                    console.log(`Player ${player.name} Change ${change} to Value ${value}`);
                    this.setState((prevState) => {
                        return {
                            players: prevState.players.map((x) => {
                                if (x.id === player.id) {
                                    return { ...x, [change]: value };
                                }
                                return x;
                            })
                        }
                    });
                });
            });

            const ucPlayerChanges = [ "playerAvatar",];
            ucPlayerChanges.forEach(change => {
                player.ucData.listen(change, (value) => {
                    this.setState((prevState) => {
                        return {
                            players: prevState.players.map((x) => {
                                if (x.id === player.id) {
                                    return { ...x, ucData: { ...x.ucData, [change]: value } };
                                }
                                return x;
                            })
                        }
                    });
                });
            });
        }
    }

    removePlayer(id) {
        this.setState((prevState) => {
            return { players: prevState.players.filter(x => x.id !== id), }
        });
    }

    setupStateListeners = (roomState) => {
        roomState.players.onAdd((player, key) => {
            console.log(player, "has been added at", key);
            this.checkAndAddPlayer(player);

            // player spesific listeners
            if (player.id === this.state.myId) {
                const changes = ["connected", "votedSkip", "avatar", "name", "id", "primaryPlayer"];
                changes.forEach(change => {
                    player.listen(change, (value) => {
                        this.setState(prevState => {
                            return {
                                player: {
                                    ...prevState.player,
                                    [change]: value
                                }
                            }
                        });
                    });
                });

                /* Example player state listers */
                const ucPlayerChanges = ["hasSubmittedAnswer", "playerAvatar", "playerAge",
                    "hasUploadedAvatar", "faceOffAvatar", "faceOffName",
                    "readyToSelectSub", "startReady", "team", "starbucks",
                    "answersCorrect", "submittedAnswer", "answerCorrect"];
                ucPlayerChanges.forEach(change => {
                    player.ucData.listen(change, (value) => {
                        if (player.ucData.startReady && player.ucData.team == null) {
                            this.state.room.send("player_ready")
                        }
                        this.setState(prevState => {
                            return {
                                player: {
                                    ...prevState.player,
                                    ucData: {
                                        ...prevState.player.ucData,
                                        [change]: value
                                    }
                                }
                            }
                        });
                    });
                });

                const ucPlayerArrayChanges = ["geniusSubjects"];
                ucPlayerArrayChanges.forEach(arrayName => {
                    let update = {};
                    update[arrayName] = []; 

                    this.setState(prevState => ({
                        player: {
                            ...prevState.player,
                            ucData: {
                                ...prevState.player.ucData,
                                [arrayName]: []
                            }
                        }
                    }));

                    player.ucData[arrayName].onAdd((item, index) => {
                        console.log(`${arrayName} added item ${item} at index ${index}`);
                        let newArray = [...this.state.player.ucData[arrayName]];
                        newArray[index] = item;

                        this.setState(prevState => ({
                            player: {
                                ...prevState.player,
                                ucData: {
                                    ...prevState.player.ucData,
                                    [arrayName]: newArray
                                }
                            }
                        }));
                    });

                    player.ucData[arrayName].onRemove((item, index) => {
                        console.log(`${arrayName} removed item ${item} at index ${index}`);
                        let newArray = [...this.state.player.ucData[arrayName]];
                        newArray.splice(index, 1); 

                        this.setState(prevState => ({
                            player: {
                                ...prevState.player,
                                ucData: {
                                    ...prevState.player.ucData,
                                    [arrayName]: newArray
                                }
                            }
                        }));
                    });

                    player.ucData[arrayName].onChange((item, index) => {
                        console.log(`${arrayName} changed item ${item} at index ${index}`);
                        let newArray = [...this.state.player.ucData[arrayName]];
                        newArray[index] = item;  

                        this.setState(prevState => ({
                            player: {
                                ...prevState.player,
                                ucData: {
                                    ...prevState.player.ucData,
                                    [arrayName]: newArray
                                }
                            }
                        }));
                    });
                });

                const ucPlayerObjectChanges = ["subGeniusSubjects"];
                ucPlayerObjectChanges.forEach(objectName => {
                    player.ucData[objectName].onChange((value, key) => {
                        console.log(`${objectName} changed key ${key} to value ${value}`);
                        this.setState(prevState => ({
                            player: {
                                ...prevState.player,
                                ucData: {
                                    ...prevState.player.ucData,
                                    [objectName]: {
                                        ...prevState.player.ucData[objectName],
                                        [key]: value
                                    }
                                }
                            }
                        }));
                    });

                    player.ucData[objectName].onRemove((value, key) => {
                        console.log(`${objectName} removed key ${key}`);
                        this.setState(prevState => {
                            let newObject = { ...prevState.player.ucData[objectName] };
                            delete newObject[key];

                            return {
                                player: {
                                    ...prevState.player,
                                    ucData: {
                                        ...prevState.player.ucData,
                                        [objectName]: newObject
                                    }
                                }
                            };
                        });
                    });
                });
            }
        });

        roomState.players.onRemove((player, key) => {
            console.log(JSON.stringify(player), "has been removed at", key);
            this.removePlayer(player.id);
        });

        roomState.host.listen("connected", (value) => {
            this.setState({ hostConnected: value });
        });

        const ucStateVars = ["gameState", "round", "intermission", "currentSubRound",
            "LyricLinguistData", "VanishingVowelsData", "FaceOffData",
            "SceneItData", "buzzedInPlayerId", "answerType", "teamALocked",
            "teamBLocked", "getReady", "buzzTimer", "isBuzzTimer"];
        ucStateVars.forEach((stateVar) => {
            roomState.ucData.listen(stateVar, (change) => {
                console.log(`UCData change: ${stateVar} --- ${change}`);
                let update = {};
                update[stateVar] = change;
                this.setState(update);
            });
        });

        gameRoundStateVars.forEach(arrayName => {
            let update = {};
            update[arrayName] = [];
            this.setState(update);
            console.log(`${arrayName} set`);
            roomState.ucData[arrayName].onAdd((change, index) => {
                console.log(`UC Array ${arrayName} Change ${change} at index ${index}`);
                this.setState((prevState) => {
                    // overwrite the array at the index
                    let newArray = [...prevState[arrayName]];
                    newArray[index] = change;
                    return {
                        [arrayName]: newArray
                    }
                });
            });
            roomState.ucData[arrayName].onRemove((change, index) => {
                console.log(`UC Array ${arrayName} Removed ${change} at index ${index}`);
                // set index to null
                this.setState((prevState) => {
                    return {
                        [arrayName]: prevState[arrayName].map((x, i) => {
                            if (i === index) {
                                return null;
                            }
                            return x;
                        })
                    }
                });
            });
        });

       
    }

    updateToken(token) {
        var url = new URL(window.location.href);

        try {
            window.history.replaceState(null, null, (url.pathname) + (`?token=${token}`)); 
        } catch (e) {
            console.warn(e)
        }
    }

    getRenderView() {

        switch (this.state.gameState) {
            case 'loading' :
                return this.state.player.ucData?.hasUploadedAvatar ? this.getDefaultView() : this.getUploadAvatar();
            case 'selecting_info':
                return this.getUserCategoryView();
            case 'selecting_team':
                return this.getSelectingTeamView();
            case 'playing':
                // if player doesnt have an avatar, show the upload avatar screen
                if (!this.state.player.ucData.hasUploadedAvatar) {
                    return this.getUploadAvatar();
                } else if (!this.state.player.ucData.team) {
                    return this.getSelectingTeamView();
                } else {
                    return this.getGameView()
                }
            default:
                return this.getDefaultView();
            //default: 
            //    return this.getGameView()
        }
    }



    getDefaultView() {
        return <DefaultView room={this.state.room} player={this.state.player} hostConnected={this.state.hostConnected} players={this.state.players}/>
    }

    getUploadAvatar() {
        return <UploadAvatar room={this.state.room} player={this.state.player} hostConnected={this.state.hostConnected} players={this.state.players} />
    }

    getUserCategoryView() {
        return <CategorySelect room={this.state.room} player={this.state.player} hostConnected={this.state.hostConnected} players={this.state.players} />
    }

    getSelectingTeamView() {
        return <SelectingTeam room={this.state.room} player={this.state.player} hostConnected={this.state.hostConnected} players={ this.state.players} />
    }

    getGameView() {
        return <GameView room={this.state.room} player={this.state.player}
            hostConnected={this.state.hostConnected} players={this.state.players}
            round={this.state.round} correctAnswer={this.state.correctAnswer.filter(x => x)}
            intermission={this.state.intermission} WhereInTheWorldData={this.state.WhereInTheWorldData}
            buzzedInPlayerId={this.state.buzzedInPlayerId} answerType={this.state.answerType}
            teamALocked={this.state.teamALocked} teamBLocked={this.state.teamBLocked}
            LyricLinguistData={this.state.LyricLinguistData}
            currentSubRound={this.state.currentSubRound} getReady={this.state.getReady}
            buzzTimer={this.state.buzzTimer} isBuzzTimer={this.state.isBuzzTimer}
        />
    }

    getIntermission() {
        return <Intermission room={this.state.room} player={this.state.player} hostConnected={this.state.hostConnected} players={this.state.players} />
    }

    onChangeRoomId = async (e) => {
        const roomId = e.target.value.toUpperCase();

        if (roomId.length === 4) {
            this.setState({ roomId, });
            await this.checkGameExists(roomId);
        } else {
            this.setState({ canJoin: false, joinButtonValue: "JOIN", roomId });
        }
    };

    onChangeNickname = (e) => {
        const re = /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/;
        const nickname = e.target.value.toUpperCase();
        if (nickname === '' || re.test(nickname)) {
            this.setState({ nickname });
        }
    };


    clickJoinRoom = async (e) => {
        e.preventDefault();
        await this.handleJoinRoom();
    }

    async handleJoinRoom(override = null) {
        if (this.state.joinButtonValue === "Reconnect") {
            this.doReconnect(this.state.token);
        } else {

            let roomId = override ? override : this.state.roomId;
            const gameCheckData = await this.checkGameExists(roomId);

            if (!this.state.loading) {
                if (gameCheckData != null && gameCheckData.playerExists) {
                    let token = gameCheckData.token;
                    this.doReconnect(token);
                } else {
                    this.setState({ loading: true, errorMessage: "", error: false, });

                    let options = {};
                    let name = this.state.nickname.length > 0 ? this.state.nickname : "PLAYER";
                    options.name = name;

                    if (name !== "PLAYER") {
                        // store in local storage
                        try {
                            localStorage.setItem("nickname", name);
                        } catch (e) {
                            console.log(e);
                        }
                    }

                    this.doJoin(roomId, options);
                }
            }

        }
    };

    storeSessionId(id) {
        try {
            localStorage.setItem("sessionId", id);
        } catch (e) {
            console.log(e);
        }
        this.setState({ storedSessionId: id });
    }

    getSessionIdFromStorage() {
        try {
            return localStorage.getItem('sessionId');
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    checkGameExists = async (roomId) => {
        const storedSessionId = this.getSessionIdFromStorage();

        if (this.cancelToken != null) {
            this.cancelToken.cancel("Operation canceled due to new request.")
        }

        if (roomId != null) {
            this.cancelToken = axios.CancelToken.source();
            
            return await axios.get(process.env.REACT_APP_GAME_SERVER_API_ENDPOINT + `/game-exists/?roomId=${roomId}&sessionId=${storedSessionId}`,
                {
                    cancelToken: this.cancelToken.token,
                }
            ).then((res) => {
                console.log("Game exists check result");
                console.log(JSON.stringify(res));
                let errorMessage = "";

                if (res.data.gameExists) {
                    errorMessage = "";
                    if (res.data.playerExists) {
                        this.setState({ canJoin: true, });
                        this.setState({ joinButtonValue: "Reconnect", errorMessage, });

                        if (res.data.token.length > 0) {
                            this.setState({ token: res.data.token })
                        }
                    } else {
                        this.setState({ canJoin: !res.data.gameFull, });
                        this.setState({ joinButtonValue: "Join", errorMessage, });
                    }
                } else {
                    errorMessage = `Room '${roomId}' does not exist...`;
                    this.setState({ canJoin: false, errorMessage, });
                }
                this.cancelToken = null;
                return res.data;
            }).catch((error) => {
                if (axios.isCancel(error)) {
                    console.log('Request canceled');
                }
                console.log({ error });

                this.setState({ connectionError: true });

                return null;
            });
        }
    }

    initRoom(room) {
        console.log(room.sessionId, "joined", room.name);
        this.setState({ room: room, roomId: room.id, myId: room.sessionId, reconnectionToken: room.reconnectionToken });
        this.updateToken(room.reconnectionToken);
        room.send("update_player_token", { reconnectionToken: room.reconnectionToken });

        room.onStateChange.once((roomState) => {
            console.log("this is the first room state!", roomState);
            const player = roomState.players[room.sessionId];
            //if (!player) window.location = this.getRedirectURL();
            this.setState({ roomState: roomState, player, isPaused: roomState.isPaused, });
            this.setupStateListeners(roomState)
            this.startLocationChecks();
            console.log(roomState)
        });


        room.onMessage("show_tutorial", (message) => {
            console.log("show_tutorial", "received on", room.name, message);
            this.setState({ doingTutorial: true });
        });
        room.onMessage("end_tutorial", (message) => {
            console.log("end_tutorial", "received on", room.name, message);
            this.setState({ doingTutorial: false });
        });
        room.onMessage("toggle_pause", (message) => {
            console.log("toggle_pause", "received on", room.name, message);
            this.setState({ isPaused: message.pause });
        });

        // your game message handlers here

        room.onMessage("catchup", (message) => {
            console.log("catchup", "received on", room.name, message);
            if (message.data.id === this.state.myId) {
                this.doCatchup(message.gameState, message.data);
            }
        });

        room.onError((code, message) => {
            console.log(this.client.id, "couldn't join", room.name);
        });
        room.onLeave((code) => {
            console.log(this.client.id, "left", room.name);
        });
    }

    doJoin = (roomId, options) => {
        this.client.joinById(roomId, options).then(room => {
            this.storeSessionId(room.sessionId);
            this.initRoom(room);
        }).catch(e => {
            console.log("JOIN ERROR", e);
            this.setState({ loading: false, errorMessage: e.Message, });
        });
    }

    doReconnect = (token) => {
        // fetch room and session id from query params
        //const sessionId = this.getQueryStringValue("sessionId");
        //const roomId = this.getQueryStringValue("roomId");
        if (!token) token = this.getQueryStringValue("token");
        this.setState({ loading: true, errorMessage: "", error: false, });

        // start reconnecting player to game
        this.client.reconnect(token).then(room => {
            this.initRoom(room);
        }).catch(e => {
            console.log("RECONNECT ERROR", e);
        });

    }

    render() {
        return (
            <div>
                {
                    this.state.room ?
                        //<div id="clientContainer" className={`${styles.clientContainer} ${this.state.player.ycsuData.teamIndex != null ? this.state.player.ycsuData.teamIndex === 0 ? styles.teamA : styles.teamB : null}`}>
                            <div id="clientContainer" className={`${styles.clientContainer}`}>
                            {
                                this.state.isPaused &&
                                <div className={styles.pauseContainer}>
                                    <div className={styles.pauseText}>Paused</div>
                                </div>
                            }
                            {
                                this.getRenderView()
                            }
                        </div>
                        :
                        this.state.loading ?
                            <Loading loadingText={"Connecting you to the game..."} noBg={true} hideLoader={false} />
                            :
                            <FormPage>
                                <div>
                                    <h2 className={formStyles.formTitle}>Join a Game</h2>
                                    <Form onSubmit={this.clickJoinRoom} >
                                        <p>To join a room, just enter the Room Code displayed on your hosts device.<br />Or simply scan the QR Code.</p>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Room Code</Form.Label>
                                            <Form.Control type="text" autoComplete="off" maxLength={4} placeholder="Enter Room Id" style={{ textTransform: "uppercase" }} value={this.state.roomId} required onChange={this.onChangeRoomId} />
                                            {
                                                this.state.errorMessage.length > 0 &&
                                                <Alert variant={"danger"}>
                                                    {this.state.errorMessage}
                                                </Alert>
                                            }
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Nickname</Form.Label>
                                            <Form.Control type="text" autoComplete="off" placeholder="Enter Nickname" style={{ textTransform: "uppercase" }} value={this.state.nickname} maxLength={10} minLength={2} required={this.state.joinButtonValue !== "Reconnect"} onChange={this.onChangeNickname} />
                                        </Form.Group>
                                        <button className={formStyles.button} disabled={!this.state.canJoin} variant="primary" type="submit">
                                            {this.state.loading ? <Spinner animation="border" /> : this.state.joinButtonValue}
                                        </button>
                                    </Form>
                                </div>
                            </FormPage>
                }
            </div>
        );
    }
}
