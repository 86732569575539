import React, { Component, useEffect } from 'react';
import './HostGameView.scss';

export default class DefaultView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            room: props.room,
            players: props.players,

        }
    }

    render() {
        return (
            <div>
                Default View
            </div>
        )
    }



}