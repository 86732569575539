import http from "helpers/http.js";

const API_URL = process.env.REACT_APP_API_URL;

const register = (email, password) => {
    var data = JSON.stringify({
        "Email": email,
        "Password": password,
        "Country": "GB"
    });

    var config = {
        method: 'post',
        url: API_URL + '/api/Authenticate/register-no-voucher',
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };

    return http(config)
        .then(function (response) {
            console.log(JSON.stringify(response.data));
            const status = response.status;
            console.log("Register Response: ", JSON.stringify(response));

            return response;
        })
        .catch(function (error) {
            console.log(error);
            return error.response.data;
        });
};


const login = (email, password, QRSessionID) => {
    var data = JSON.stringify({
        "Email": email,
        "Password": password,
        "QRSessionID": QRSessionID,
    });

    var configLogin = {
        method: 'post',
        url: API_URL + '/api/Authenticate/login',
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };

    return http(configLogin)
        .then(function (response) {
            if (response.data.token) {
                localStorage.setItem("user", JSON.stringify(response.data));
            }
            //localStorage.setItem("games", JSON.stringify(response.data.games));
            console.log("Login Response : ", JSON.stringify(response));
            return response.data;

        }).catch(function (error) {
            console.log(error);
            return error.response.data;
        });
};


const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("games");
};

const getGames = (email) => {
    var configGetGames = {
        method: 'post',
        url: API_URL + '/api/Authenticate/GetGames',
        headers: {
            'Content-Type': 'application/json'
        },
        data: {
            email,
        }
    };

    return http(configGetGames)
        .then(function (response) {
            return response.data;

        }).catch(function (error) {
            console.log(error);
            return error.response.data;
        });
};

const redeemCode = (code) => {
    var configGetGames = {
        method: 'post',
        url: API_URL + '/api/Authenticate/ApplyVoucherCode',
        headers: {
            'Content-Type': 'application/json'
        },
        data: {
            voucherCode: code,
        }
    };

    return http(configGetGames)
        .then(function (response) {
            return response.data;

        }).catch(function (error) {
            console.log(error);
            return error.response.data;
        });
}

const checkCode = (code) => {
    var configGetGames = {
        method: 'post',
        url: API_URL + '/api/Authenticate/CheckVoucherCode',
        headers: {
            'Content-Type': 'application/json'
        },
        data: {
            voucherCode: code,
        }
    };

    return http(configGetGames)
        .then(function (response) {
            return response.data;

        }).catch(function (error) {
            console.log(error);
            return error.response.data;
        });
}

const redeemMultipleCodes = (codes) => {
    var configGetGames = {
        method: 'post',
        url: API_URL + '/api/Authenticate/ApplyMultipleVoucherCodes',
        headers: {
            'Content-Type': 'application/json'
        },
        data: {
            voucherCodes: codes,
        }
    };

    return http(configGetGames)
        .then(function (response) {
            return response.data;

        }).catch(function (error) {
            console.log(error);
            return error.response.data;
        });
}

const forgotPassword = (email) => {
    var data = JSON.stringify({
        "Email": email,
    });

    var configForgotPassword = {
        method: 'post',
        url: API_URL + '/api/Authenticate/forgot-password',
        headers: {
            'Content-Type': 'application/json'
        },
        data,
    };

    return http(configForgotPassword)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            console.log(error);
            return error.response.data;
        });
}

const resetPassword = (email, token, password, confirmPassword) => {
    var data = JSON.stringify({
        "Email": email,
        "Token": token,
        "Password": password,
        "ConfirmPassword": confirmPassword
    });

    var configReset = {
        method: 'post',
        url: API_URL + '/api/Authenticate/reset-password',
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };

    return http(configReset)
        .then((response) => {
            return response.data;

        }).catch((error) => {
            console.log(error);
            return error.response.data;
        });
}

const requestQRSession = (oldSessionID) => {
    var data = JSON.stringify({
        "OverwriteSessionID": oldSessionID
    });

    var request = {
        method: 'post',
        url: API_URL + '/api/Authenticate/request-qr-session',
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };
    console.log("requestQRSession: ", request);
    return http(request)
        .then(function (response) {
            if (!response) {
                console.error("no responce from: ", request);
            }
            if (response.data?.sessionID) {
                localStorage.setItem("QRSessionData", JSON.stringify(response.data));
            }
            return response.data;

        }).catch(function (error) {
            console.log(error);
            return error.response?.data;
        });
}

const getQRSession = (QRSessionID) => {
    var data = JSON.stringify({
        "QRSessionID": QRSessionID,
    });

    var request = {
        method: 'post',
        url: API_URL + '/api/Authenticate/get-qr-session',
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };

    return http(request)
        .then(function (response) {
            return response.data;

        }).catch(function (error) {
            console.log(error);
            return { ...error.response.data, error };
        });
}

const autoLoginQRSession = (QRSessionID, UserID) => {
    var data = JSON.stringify({
        "QRSessionID": QRSessionID,
        "UserID": UserID,
    });

    var request = {
        method: 'post',
        url: API_URL + '/api/Authenticate/auto-login-qr-session',
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };

    return http(request)
        .then(function (response) {
            return response.data;

        }).catch(function (error) {
            console.log(error);
            return { ...error.response.data, error };
        });
}


const getLatestRoomID = (QRSessionID) => {
    var data = JSON.stringify({
        "QRSessionID": QRSessionID,
    });

    var request = {
        method: 'post',
        url: API_URL + '/api/Authenticate/get-roku-room-id',
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };

    return http(request)
        .then(function (response) {
            return response.data;

        }).catch(function (error) {
            console.log(error);
            return { ...error.response.data, error };
        });
}


export default {
    register,
    login,
    logout,
    getGames,
    redeemCode,
    redeemMultipleCodes,
    forgotPassword,
    resetPassword,
    checkCode,
    requestQRSession,
    getQRSession,
    autoLoginQRSession,
    getLatestRoomID,
};
