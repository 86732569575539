import React, { Component, useState, useEffect } from 'react';

import './HostGameView.scss';
import ReactPlayer from 'react-player';

export default class LyricLinguist extends Component {
    constructor(props) {
        super(props);
        this.state = {
            room: props.room,
            players: props.players,
            correctAnswer: props.correctAnswer,
            LyricLinguistData: props.LyricLinguistData || [],
            lyricLinguistVideo: props.LyricLinguistData[0] || "",
            answerPool: [],
            fakeQuestions: [],
            playingVideo: false,
        };
        this.videoRef = React.createRef();
    }

    componentDidMount() {
        const rawData = Array.from(this.props.LyricLinguistData);

        // Initialize video and fake questions
        this.setState(
            {
                playingVideo: false,
                LyricLinguistData: this.props.LyricLinguistData,
                lyricLinguistVideo: rawData[0],
                fakeQuestions: [rawData[1], rawData[2]],
            },
            () => {
                // Set the answer pool after initializing state
                this.setAnswerPool();
            }
        );
    }

    setAnswerPool() {
        const pool = [...this.state.fakeQuestions, this.props.correctAnswer[0]];
        pool.sort(() => Math.random() - 0.5); // Shuffle the pool
        this.setState({ answerPool: pool });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.LyricLinguistData !== this.props.LyricLinguistData) {
            console.log("shush", this.props.LyricLinguistData);
            const rawData = Array.from(this.props.LyricLinguistData);
            const mp4Url = rawData[0];
            console.log("mp4 url", mp4Url);
            this.setState(
                {
                    lyricLinguistVideo: mp4Url,
                    fakeQuestions: [rawData[1], rawData[2]],
                },
                () => {
                    this.setAnswerPool();
                }
            );
        }
    }

    handleEnded = () => {
        this.setState({ playingVideo: false });
        setTimeout(() => this.setState({ playingVideo: true }), 4000);
    };

    render() {
        console.log(this.props.showCorrectAnswer)
        return (
            <div className={`lyric-linguist-container ${this.props.show && "show"}`}>
                <div className="lyric-linguist-video-wrapper">
                    {this.props.message !== "What's the connection?" && this.props.timer && this.props.timer !== 0 ? (
                        <div className="lyric-linguist-timer">
                            <div className="lyric-linguist-timer-border">

                                {
                                    this.props.timer !== 0 && (<h2>{this.props.timer}</h2>)

                                }
                            </div>
                        </div>
                    ) : <></>}
                    <ReactPlayer
                        ref={this.videoPlayer}
                        url={this.state.lyricLinguistVideo}
                        className="lyric-linguist-video"
                        width="100%"
                        height="100%"
                        onEnded={this.handleEnded}
                        playing={this.props.show || this.state.playingVideo}
                    />
                </div>
                <MultipleChoice
                    answer={this.props.correctAnswer}
                    showCorrectAnswer={this.props.showCorrectAnswer}
                    RNGPool={this.state.answerPool}
                />
            </div>
        );
    }
}

const MultipleChoice = ({ answer, showCorrectAnswer, RNGPool = [] }) => {
    const [answerPool, setAnswerPool] = useState([]);

    useEffect(() => {
        setAnswerPool(RNGPool);
    }, [RNGPool]);

    return (
        <div className="multiple-choice-container">
            {answerPool.map((option, index) => (
                <div className={`multiple-choice-option-container ${showCorrectAnswer && option === answer[0] ? "correct" : ""}`} >

                    <div
                        key={index}
                        className={`multiple-choice-option ${showCorrectAnswer && option === answer[0] ? "correct" : ""
                            }`}
                    >
                        {
                            console.log("RAHHH", option, answer[0])
                        }
                        <h1>
                            {option}

                        </h1>
                    </div>
                </div>
            ))}
        </div>
    );
};


const ConnectFourLetterBox = ({ answer, showCorrectAnswer }) => {
    const [splitAnswer, setSplitAnswer] = useState([]);

    useEffect(() => {
        const formattedAnswer = Array.isArray(answer) ? answer.join('') : answer;
        const slicedWord = typeof formattedAnswer === 'string' ? formattedAnswer.split('') : [];
        setSplitAnswer(slicedWord);
        console.log(slicedWord);
    }, [answer]);

    return (
        <div className={`connect-four-letter-box-container ${showCorrectAnswer ? 'show-correct-answer-bounce' : 'CFLB-start'}`}>
            {splitAnswer.map((letter, index) => (
                <div key={index} className={`connect-four-letter ${letter === ' ' ? 'connect-four-space' : ''}`}>
                    {
                        showCorrectAnswer && (
                            <h1>{letter}</h1>
                        )
                    }
                </div>
            ))}
        </div>
    );
};
