export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const SET_GAMES = "SET_GAMES";
export const AUTHORISE = "AUTHORISE";
export const QR_SESSION_REQUEST = "QR_SESSION_REQUEST";
export const QR_SESSION_REQUEST_FAILED = "QR_SESSION_REQUEST_FAILED";
export const QR_SESSION_CHECK = "QR_SESSION_CHECK";
export const QR_SESSION_CHECK_FAIL = "QR_SESSION_CHECK_FAIL";
export const QR_SESSION_LOGIN = "QR_SESSION_LOGIN";
export const QR_SESSION_LOGIN_FAIL = "QR_SESSION_LOGIN_FAIL";
export const GET_LATEST_OPEN_ROOM_ID = "GET_LATEST_OPEN_ROOM_ID";
export const GET_LATEST_OPEN_ROOM_ID_FAILED = "GET_LATEST_OPEN_ROOM_ID_FAILED";