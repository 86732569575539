import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js/pure";
import { useSelector, useDispatch } from 'react-redux'
import { Elements, } from "@stripe/react-stripe-js";
import StripeService from "../services/stripe.service";
import Spinner from 'react-bootstrap/Spinner';

import CheckoutForm from "./Forms/CheckoutForm";
import FormPage from "./Utility/FormPage";
import { Container, Row, Form, } from "react-bootstrap";
import styles from "./CheckoutStyles.module.scss";

const game = {
    name: "Universally Challenged",
    price: 9.99,
    quantity: 1,
    productId: process.env.REACT_APP_UC_PID,
}

export default function Checkout() {
    const [clientSecret, setClientSecret] = useState("");
    const [checkoutStep, setCheckoutStep] = useState(false);
    const [checkoutItems, setCheckoutItems] = useState([]);
    const [currency, setCurrency] = useState("");
    const [loadingCheckout, setLoadingCheckout] = useState(false);
    const [cartTotal, setCartTotal] = useState(0);
    const [stripePromise, setStripePromise] = useState(null);

    const [email, setEmail] = useState("");

    const { isLoggedIn, user } = useSelector(state => state.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_KEY));
        console.log("Looking for discount in redux");

        if (isLoggedIn) {
            setEmail(user.email);
        }

    }, []);

    const appearance = {
        theme: 'flat',
        variables: {

        }
    };
    const options = {
        clientSecret,
        appearance,
        loader: "auto"
    };


    const continueToCheckout = (e) => {
        e.preventDefault();

        setLoadingCheckout(true);
        StripeService.createPaymentIntent(game, email)
            .then((res) => {
                setLoadingCheckout(false);
                setCheckoutStep(true);
                console.log("res data ", res.data);
                setCartTotal(res.data.cartTotal);
                setClientSecret(res.data.clientSecret)
                setCheckoutItems(res.data.checkoutItems);
                setCurrency(res.data.currency);
            }).catch((err) => {
                console.log("Error : ", err);
                setLoadingCheckout(false);
            });
    }

    const onChangeEmail = (e) => {
        const email = e.target.value;

        setEmail(email);
    };

    const isDev = () => { return !process.env.REACT_APP_ENVIRONMENT || process.env.REACT_APP_ENVIRONMENT === 'development' }

    const getTotalSavings = () => {
        let amount = 0;
        checkoutItems.forEach((item) => {
            if (item.discountAmount) {
                amount += item.discountAmount;
            }
        });
        console.log(`total savings : ${amount}`)
        if (amount === 0) return null;
        else return `${currency}${(amount / 100).toFixed(2)}`;
    }


    const renderItemList = (item, title) => {
        return <table className={styles.checkoutTable}>
            <tr>
                <th>{title}</th>
                <th>Price</th>
                {
                    !checkoutStep &&
                    <th></th>
                }
            </tr>
            {
                renderItemRow(item)
            }
        </table>
    }

    const renderItemRow = (item) => {
        return <tr>
            <td>{item.name}</td>
            <td>
                {`${item.price}` || <Spinner animation="border" size="sm" />}{` `}
            </td>
        </tr>
    }

    return (
                <Container>
                    <Row>

                        <div className={`${styles.checkoutForm}`}>
                            <h2 className={styles.formTitle}>Purchase Game</h2>
                            {renderItemList(game, "Game")}
                            {
                                checkoutStep &&
                                <table className={styles.checkoutTable}>
                                    <tr className={styles.total}>
                                        <td className={styles.buffer}></td>
                                        <td className={styles.title}>Total</td>
                                        <td>{cartTotal} {/*{discountProducts == null || discountProducts.length == 0 && discount}*/}</td>
                                    </tr>
                                    {
                                        getTotalSavings() != null &&
                                        <tr className={styles.totalSavings}>
                                            <td></td>
                                            <td>Total Savings</td>
                                            <td>{getTotalSavings()}</td>
                                            <td className={styles.bufferSmall}></td>
                                        </tr>
                                    }
                                </table>
                            }

                            {
                                !checkoutStep &&
                                <>
                                    <Form onSubmit={continueToCheckout}>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Email address</Form.Label>
                                            <Form.Control disabled={loadingCheckout} type="email" placeholder="Enter email" required onChange={onChangeEmail} value={email} />
                                        </Form.Group>
                                        <button disabled={loadingCheckout} className={`${styles.button} ${styles.wide}`} type="submit" id="submit">{loadingCheckout ? <Spinner animation="border" /> : 'Checkout'}</button>
                                    </Form>
                                </>
                            }
                        </div>
                    </Row>
                    {
                        checkoutStep &&
                        <Row xs={12}>
                            {clientSecret ?
                                <Elements options={options} stripe={stripePromise}>
                                    <CheckoutForm email={email} />
                                </Elements>
                                :
                                <div className={`${styles.spinner}`} id="spinner"></div>
                            }
                        </Row>
                    }

                </Container>
            
    );
}