import React, { Component } from 'react';
import './GameView.scss'; // Import your SCSS file

export default class GridSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            indicatorPosition: null,
            userAnswer: null,
        };
        this.canvasRef = React.createRef();
        this.mapImage = new Image();
    }

    componentDidMount() {
        this.updateMapImage(this.props.WhereInTheWorldData.filter(x => x)[1]);
        window.addEventListener('resize', this.resizeCanvas);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeCanvas);
    }

    componentDidUpdate(prevProps) {
        if (this.props.WhereInTheWorldData && this.props.WhereInTheWorldData.filter(x => x)[1] !== prevProps.WhereInTheWorldData.filter(x => x)[1]) {
            this.updateMapImage(this.props.WhereInTheWorldData.filter(x => x)[1]);
        }
    }

    updateMapImage = (url) => {
        this.mapImage.src = url;
        this.mapImage.onload = () => {
            this.imageNaturalWidth = this.mapImage.width;
            this.imageNaturalHeight = this.mapImage.height;
            this.resizeCanvas();
        };
        this.mapImage.onerror = () => {
            console.error('Failed to load map image:', url);
        };
    };

    resizeCanvas = () => {
        const canvas = this.canvasRef.current;

        if (canvas) {
            const displayWidth = window.innerWidth * 0.9; 
            const displayHeight = window.innerHeight * 0.6; 
            canvas.style.width = `${displayWidth}px`;
            canvas.style.height = `${displayHeight}px`;

          
            canvas.width = displayWidth;
            canvas.height = displayHeight;

            this.drawCanvas();
        }
    };

    handleCanvasClick = (event) => {
        const canvas = this.canvasRef.current;
        const rect = canvas.getBoundingClientRect();

        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        const dx = x - this.offsetX;
        const dy = y - this.offsetY;

        const normalizedX = dx / this.finalWidth;
        const normalizedY = dy / this.finalHeight;

        const clampedX = Math.max(0, Math.min(1, normalizedX));
        const clampedY = Math.max(0, Math.min(1, normalizedY));

        const imageX = clampedX * this.imageNaturalWidth;
        const imageY = clampedY * this.imageNaturalHeight;

        console.log("Canvas click position:", x, y);
        console.log("Adjusted position:", dx, dy);
        console.log("Normalized position:", clampedX, clampedY);
        console.log("Image pixel position (user's answer):", imageX, imageY);

        this.setState(
            {
                indicatorPosition: { x: x, y: y },
                userAnswer: { x: imageX, y: imageY },
            },
            () => {
                this.drawCanvas();
                console.log("User's answer stored in state:", this.state.userAnswer);
            }
        );

        this.props.handleUserInput([ imageX,imageY ]);
    };

    drawCanvas = () => {
        const canvas = this.canvasRef.current;
        const ctx = canvas.getContext('2d');

        const { width: canvasWidth, height: canvasHeight } = canvas;

        ctx.clearRect(0, 0, canvasWidth, canvasHeight);

        const paddingX = canvasWidth * 0;
        const paddingY = canvasHeight * 0;
        const drawWidth = canvasWidth - 2 * paddingX;
        const drawHeight = canvasHeight - 2 * paddingY;

        const imageAspectRatio = this.mapImage.width / this.mapImage.height;
        const canvasAspectRatio = drawWidth / drawHeight;

        let finalWidth, finalHeight, offsetX, offsetY;

        if (imageAspectRatio > canvasAspectRatio) {
            finalWidth = drawWidth;
            finalHeight = drawWidth / imageAspectRatio;
            offsetX = paddingX;
            offsetY = paddingY + (drawHeight - finalHeight) / 2;
        } else {
            finalHeight = drawHeight;
            finalWidth = drawHeight * imageAspectRatio;
            offsetX = paddingX + (drawWidth - finalWidth) / 2;
            offsetY = paddingY;
        }

        this.offsetX = offsetX;
        this.offsetY = offsetY;
        this.finalWidth = finalWidth;
        this.finalHeight = finalHeight;

        ctx.drawImage(this.mapImage, offsetX, offsetY, finalWidth, finalHeight);

        if (this.state.indicatorPosition) {
            const { x, y } = this.state.indicatorPosition;
            ctx.fillStyle = 'red';
            ctx.beginPath();
            ctx.arc(x, y, 5, 0, 2 * Math.PI);
            ctx.fill();
        }
    };

    render() {
        return (
            <div className="grid-select-container">
                <canvas
                    ref={this.canvasRef}
                    className="map-canvas"
                    onClick={this.handleCanvasClick}
                />
            </div>
        );
    }
}
