import React, { Component } from 'react';
import './GameView.scss'; // Import your SCSS file

export default class MultiChoice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            correctAnswer: props.answer,
            LyricLinguistData: props.LyricLinguistData || [],
        };
     
    }


    render() {
        let mixedpool = [this.props.LyricLinguistData[1], this.props.LyricLinguistData[2], this.props.answer];
        let randomPool = mixedpool.sort(() => Math.random() - 0.5);
        console.log(randomPool);
        return (
            <div className="user-multi-choice-container">
                {
                    randomPool.map((answer, index) => (
                        <div className="user-multi-choice-answer-container">
                            <div key={index} className="user-multi-choice-answer" onClick={() => this.props.userInput(answer)}>
                                {answer}
                            </div>
                        </div>
                       
                    ))
                }
            </div>
        );
    }
}
